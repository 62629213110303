.hover-cards {

	&__grid {
		display: grid;
			gap: $spacing;
			grid-template-columns: 1fr;
			grid-template-rows: auto;
	}
	
	&__item {
		position: relative;
		display: inline-block; /* Make the width of box same as image */

		&--front {
			@include size(100%);
			overflow: hidden;
			position: absolute;
				top: 0;
				left: 0;
			transition: .35s;

			&:after {
				@include size(100%);
				background: linear-gradient(180deg, rgba(55, 57, 59, 0) 30%, $tealDarkest 100%);
				content: '';
				opacity: 0.9;
				position: absolute;
					top: 0;
					left: 0;
			}

			img {
				@include object-fit(cover, center);
				transition: .35s;
			}
		}

		&--footer {
			position: absolute;
			z-index: 999;
			margin: 0 auto;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			padding: $spacing;

			h3 {
				font-weight: bold;
				font-size: 1.25rem;
			}

			h3, .arrow-link {color: $white;}
		}

		&-text {
			opacity: 0;
		}
	}

    @include media($screen-sm-max, 'max') {
        &__item {
            max-height: 13rem;
            overflow: hidden;
            width: 100%;

			&--text {
				height: 13rem;
				overflow: hidden;
			}
        }
    }

    @include media($screen-sm) {

		&__grid {
			grid-template-columns: repeat(2, 1fr);
		}

		&__item {
			height: 100%;
			min-height: 20rem;

			&--footer {
				display: flex;
			}

			&--text {
				color: $white;
				background: linear-gradient(90deg, $tealDarker 10%, $tealDarkest 100%);
				height: 100%;
				opacity: 0;
				padding: 1.5rem;
				position: relative;
				transition: opacity .25s;
				z-index: 2;
	
				p { margin-bottom: 1rem;}
	
				ul { margin: 0; }
			}

			&--front {

				&::after {
					background: linear-gradient(180deg, rgba(55, 57, 59, 0) 50%, $tealDarkest 90%);
				}
			}


			&:hover {
				.hover-cards__item--front img {
					opacity: 0;
				}
				
				.hover-cards__item--text {
					opacity: 1;
				}
			}
		}
    }
}
