/**
 * Page Header Flex Layout
 * DBS>Interactive
 */

.page-header {

    &__text {
        animation: opacity $animationDuration ease-in-out both, slideRight $animationDuration ease-out both;
        margin-bottom: $spacing;

        hr {
            border-color: $teal;
            border-width: 3px;
            margin-bottom: $spacing;
            margin-top: $spacing;
            margin-left: 0;
            width: 5rem;

            &:first-child {margin-top: 0;}
        }
    }

    &__image {
        animation: opacity $animationDuration #{$animationDuration/2} ease-in-out both;

        &-wrapper {
            height: 0;
            overflow: hidden;
            padding-bottom: 92%;
            position: relative;

            img { @include object-fit(cover, center); }
        }
    }
    
    @include media($screen-sm) {

        &__text {
            margin-bottom: 0;
            padding-right: 3rem;
            width: 50%;
        }

        &__image {
            width: 50%;
        }
    }

    @include media($screen-md) {

        &__text {
            width: 45%;
        }

        &__image {
            width: 55%;
        }

        &.project-header {
            
            .page-header__text {width: 50%;}
            .page-header__image {width: 50%;}
        }
    }

    @include media($mobileNavBP) {
        background: linear-gradient(180deg, #474747 0%, $bodyBackground 43.76%), #D9D9D9;
        margin-top: 0;
        padding-top: 12rem;
    }
}