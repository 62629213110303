/**
 * DBS Slate Main Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will be loaded on pages that aren't the front page or blog.
 */
/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Used for mimicking the vertical space provided by the default-margin mixin, but
 * with padding for colored background layouts.
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Fonts
 */
/**
 * Type style definitions
 */
.advantages__left {
  margin-bottom: 3rem;
  opacity: 0;
  transition: 0.75s opacity ease-in-out; }

.advantages__row {
  margin-bottom: 2rem;
  opacity: 0;
  transform: translate3d(3rem, 0, 0);
  transition: 0.75s opacity ease-in-out, 0.75s transform ease-in-out; }
  .advantages__row-top {
    margin-bottom: .75rem; }
  .advantages__row-icon {
    height: 3rem;
    width: 3rem;
    position: relative; }
    .advantages__row-icon img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .advantages__row-icon img {
          height: 100%;
          object-fit: contain;
          object-position: left center; } }
  .advantages__row.in-view {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

.advantages .animation-trigger.in-view + .advantages__left {
  opacity: 1; }

@media screen and (min-width: 48em) {
  .advantages__left {
    margin-bottom: 0;
    padding-right: 1.5rem;
    width: 50%; }
  .advantages__right {
    padding-left: 1.5rem;
    width: 50%; }
  .advantages__row-icon {
    height: 4rem;
    width: 4rem; } }

.blueprint {
  padding: 6rem 0 !important;
  position: relative; }
  .blueprint__text {
    opacity: 0;
    transition: opacity 0.75s ease-in-out; }
  .blueprint .animation-trigger.in-view ~ .contain .blueprint__text {
    opacity: 1; }
  .blueprint__graphic {
    height: 80%;
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translateY(-50%);
    width: 50%; }
    .blueprint__graphic svg {
      height: 100%;
      width: 100%; }
  .blueprint #house-drawing .line {
    fill: transparent;
    stroke: rgba(255, 255, 255, 0.25);
    stroke-width: 3;
    stroke-dashoffset: 10000;
    stroke-dasharray: 10000;
    transition: 8s; }
  .blueprint #house-drawing.in-view .line {
    stroke-dashoffset: 0; }
  @media screen and (min-width: 48em) {
    .blueprint {
      padding: 7.5rem 0 !important; }
      .blueprint__text {
        max-width: 35rem; }
      .blueprint.text-center .blueprint__text {
        margin-left: auto;
        margin-right: auto; }
      .blueprint.text-right .blueprint__text {
        margin-left: auto; }
      .blueprint.text-right .blueprint__graphic {
        left: auto;
        right: 48%; } }

.builds__header {
  margin-bottom: 4.5rem; }
  .builds__header-text {
    animation: opacity 0.75s both;
    margin-bottom: 3rem;
    max-width: 38rem; }
    .builds__header-text p {
      max-width: 29rem; }
  .builds__header-map {
    margin-left: -1rem;
    margin-right: -1rem;
    position: relative; }
    .builds__header-map-dots {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
    .builds__header-map--image {
      height: 85%;
      width: 85%;
      border-radius: 50%;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: .25s; }
      .builds__header-map--image img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .builds__header-map--image img {
            height: 100%;
            object-fit: cover;
            object-position: center; } }
    .builds__header-map-dot {
      height: 0.25rem;
      width: 0.25rem;
      animation: partialOpacity .25s both;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      position: absolute;
      transform-origin: bottom; }
      .builds__header-map-dot::before {
        height: 0.35rem;
        width: 0.35rem;
        background: #ffffff;
        border-radius: 50%;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2; }
      .builds__header-map-dot.featured {
        height: 0.5rem;
        width: 0.5rem;
        animation: opacity .25s both;
        background: white;
        border-radius: 50%;
        transition: transform .25s; }
        .builds__header-map-dot.featured::before {
          height: 1rem;
          width: 1rem;
          opacity: .17;
          pointer-events: none;
          transition: .25s opacity; }
        .builds__header-map-dot.featured:hover, .builds__header-map-dot.featured:focus {
          transform: scale3d(5, 5, 1);
          z-index: 20; }
          .builds__header-map-dot.featured:hover .builds__header-map--image, .builds__header-map-dot.featured:focus .builds__header-map--image {
            opacity: 1; }
          .builds__header-map-dot.featured:hover::before, .builds__header-map-dot.featured:focus::before {
            opacity: 0; }
      .builds__header-map-dot:nth-child(1) {
        animation-delay: 0.06s; }
      .builds__header-map-dot:nth-child(2) {
        animation-delay: 0.12s; }
      .builds__header-map-dot:nth-child(3) {
        animation-delay: 0.18s; }
      .builds__header-map-dot:nth-child(4) {
        animation-delay: 0.24s; }
      .builds__header-map-dot:nth-child(5) {
        animation-delay: 0.3s; }
      .builds__header-map-dot:nth-child(6) {
        animation-delay: 0.36s; }
      .builds__header-map-dot:nth-child(7) {
        animation-delay: 0.42s; }
      .builds__header-map-dot:nth-child(8) {
        animation-delay: 0.48s; }
      .builds__header-map-dot:nth-child(9) {
        animation-delay: 0.54s; }
      .builds__header-map-dot:nth-child(10) {
        animation-delay: 0.6s; }
      .builds__header-map-dot:nth-child(11) {
        animation-delay: 0.66s; }
      .builds__header-map-dot:nth-child(12) {
        animation-delay: 0.72s; }
      .builds__header-map-dot:nth-child(13) {
        animation-delay: 0.78s; }
      .builds__header-map-dot:nth-child(14) {
        animation-delay: 0.84s; }
      .builds__header-map-dot:nth-child(15) {
        animation-delay: 0.9s; }
      .builds__header-map-dot:nth-child(16) {
        animation-delay: 0.96s; }
      .builds__header-map-dot:nth-child(17) {
        animation-delay: 1.02s; }
      .builds__header-map-dot:nth-child(18) {
        animation-delay: 1.08s; }
      .builds__header-map-dot:nth-child(19) {
        animation-delay: 1.14s; }
      .builds__header-map-dot:nth-child(20) {
        animation-delay: 1.2s; }
      .builds__header-map-dot:nth-child(21) {
        animation-delay: 1.26s; }
      .builds__header-map-dot:nth-child(22) {
        animation-delay: 1.32s; }
      .builds__header-map-dot:nth-child(23) {
        animation-delay: 1.38s; }
      .builds__header-map-dot:nth-child(24) {
        animation-delay: 1.44s; }
      .builds__header-map-dot:nth-child(25) {
        animation-delay: 1.5s; }
      .builds__header-map-dot:nth-child(26) {
        animation-delay: 1.56s; }
      .builds__header-map-dot:nth-child(27) {
        animation-delay: 1.62s; }
      .builds__header-map-dot:nth-child(28) {
        animation-delay: 1.68s; }
      .builds__header-map-dot:nth-child(29) {
        animation-delay: 1.74s; }
      .builds__header-map-dot:nth-child(30) {
        animation-delay: 1.8s; }
      .builds__header-map-dot:nth-child(31) {
        animation-delay: 1.86s; }
      .builds__header-map-dot:nth-child(32) {
        animation-delay: 1.92s; }
      .builds__header-map-dot:nth-child(33) {
        animation-delay: 1.98s; }
      .builds__header-map-dot:nth-child(34) {
        animation-delay: 2.04s; }
      .builds__header-map-dot:nth-child(35) {
        animation-delay: 2.1s; }
      .builds__header-map-dot:nth-child(36) {
        animation-delay: 2.16s; }
      .builds__header-map-dot:nth-child(37) {
        animation-delay: 2.22s; }
      .builds__header-map-dot:nth-child(38) {
        animation-delay: 2.28s; }
      .builds__header-map-dot:nth-child(39) {
        animation-delay: 2.34s; }
      .builds__header-map-dot:nth-child(40) {
        animation-delay: 2.4s; }
      .builds__header-map-dot:nth-child(41) {
        animation-delay: 2.46s; }
      .builds__header-map-dot:nth-child(42) {
        animation-delay: 2.52s; }
      .builds__header-map-dot:nth-child(43) {
        animation-delay: 2.58s; }
      .builds__header-map-dot:nth-child(44) {
        animation-delay: 2.64s; }
      .builds__header-map-dot:nth-child(45) {
        animation-delay: 2.7s; }
      .builds__header-map-dot:nth-child(46) {
        animation-delay: 2.76s; }
      .builds__header-map-dot:nth-child(47) {
        animation-delay: 2.82s; }
      .builds__header-map-dot:nth-child(48) {
        animation-delay: 2.88s; }
      .builds__header-map-dot:nth-child(49) {
        animation-delay: 2.94s; }
      .builds__header-map-dot:nth-child(50) {
        animation-delay: 3s; }
      .builds__header-map-dot:nth-child(51) {
        animation-delay: 3.06s; }
      .builds__header-map-dot:nth-child(52) {
        animation-delay: 3.12s; }
      .builds__header-map-dot:nth-child(53) {
        animation-delay: 3.18s; }
      .builds__header-map-dot:nth-child(54) {
        animation-delay: 3.24s; }
      .builds__header-map-dot:nth-child(55) {
        animation-delay: 3.3s; }
      .builds__header-map-dot:nth-child(56) {
        animation-delay: 3.36s; }
      .builds__header-map-dot:nth-child(57) {
        animation-delay: 3.42s; }
      .builds__header-map-dot:nth-child(58) {
        animation-delay: 3.48s; }
      .builds__header-map-dot:nth-child(59) {
        animation-delay: 3.54s; }
      .builds__header-map-dot:nth-child(60) {
        animation-delay: 3.6s; }
      .builds__header-map-dot:nth-child(61) {
        animation-delay: 3.66s; }
      .builds__header-map-dot:nth-child(62) {
        animation-delay: 3.72s; }
      .builds__header-map-dot:nth-child(63) {
        animation-delay: 3.78s; }
      .builds__header-map-dot:nth-child(64) {
        animation-delay: 3.84s; }
      .builds__header-map-dot:nth-child(65) {
        animation-delay: 3.9s; }
      .builds__header-map-dot:nth-child(66) {
        animation-delay: 3.96s; }
      .builds__header-map-dot:nth-child(67) {
        animation-delay: 4.02s; }
      .builds__header-map-dot:nth-child(68) {
        animation-delay: 4.08s; }
      .builds__header-map-dot:nth-child(69) {
        animation-delay: 4.14s; }
      .builds__header-map-dot:nth-child(70) {
        animation-delay: 4.2s; }
      .builds__header-map-dot:nth-child(71) {
        animation-delay: 4.26s; }
      .builds__header-map-dot:nth-child(72) {
        animation-delay: 4.32s; }
      .builds__header-map-dot:nth-child(73) {
        animation-delay: 4.38s; }
      .builds__header-map-dot:nth-child(74) {
        animation-delay: 4.44s; }
      .builds__header-map-dot:nth-child(75) {
        animation-delay: 4.5s; }
      .builds__header-map-dot:nth-child(76) {
        animation-delay: 4.56s; }
      .builds__header-map-dot:nth-child(77) {
        animation-delay: 4.62s; }
      .builds__header-map-dot:nth-child(78) {
        animation-delay: 4.68s; }
      .builds__header-map-dot:nth-child(79) {
        animation-delay: 4.74s; }
      .builds__header-map-dot:nth-child(80) {
        animation-delay: 4.8s; }
      .builds__header-map-dot:nth-child(81) {
        animation-delay: 4.86s; }
      .builds__header-map-dot:nth-child(82) {
        animation-delay: 4.92s; }
      .builds__header-map-dot:nth-child(83) {
        animation-delay: 4.98s; }
      .builds__header-map-dot:nth-child(84) {
        animation-delay: 5.04s; }
      .builds__header-map-dot:nth-child(85) {
        animation-delay: 5.1s; }
      .builds__header-map-dot:nth-child(86) {
        animation-delay: 5.16s; }
      .builds__header-map-dot:nth-child(87) {
        animation-delay: 5.22s; }
      .builds__header-map-dot:nth-child(88) {
        animation-delay: 5.28s; }
      .builds__header-map-dot:nth-child(89) {
        animation-delay: 5.34s; }
      .builds__header-map-dot:nth-child(90) {
        animation-delay: 5.4s; }
      .builds__header-map-dot:nth-child(91) {
        animation-delay: 5.46s; }
      .builds__header-map-dot:nth-child(92) {
        animation-delay: 5.52s; }
      .builds__header-map-dot:nth-child(93) {
        animation-delay: 5.58s; }
      .builds__header-map-dot:nth-child(94) {
        animation-delay: 5.64s; }
      .builds__header-map-dot:nth-child(95) {
        animation-delay: 5.7s; }
      .builds__header-map-dot:nth-child(96) {
        animation-delay: 5.76s; }
      .builds__header-map-dot:nth-child(97) {
        animation-delay: 5.82s; }
      .builds__header-map-dot:nth-child(98) {
        animation-delay: 5.88s; }
      .builds__header-map-dot:nth-child(99) {
        animation-delay: 5.94s; }

.builds__filter {
  background: #2f2f2f;
  border-radius: 0 14px 14px 14px;
  margin-bottom: 3rem;
  margin-top: 6rem;
  padding: 1.5rem;
  position: relative; }
  .builds__filter-label {
    background: #2f2f2f;
    border-radius: 18px 18px 0 0;
    padding: 1rem;
    position: absolute;
    bottom: 100%;
    left: 0; }
    .builds__filter-label h2 {
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: 2.5px;
      line-height: 1; }
  .builds__filter label {
    display: block;
    margin-bottom: 0; }
  .builds__filter select {
    appearance: none;
    background-color: transparent;
    border: none;
    color: #ffffff;
    cursor: pointer;
    margin-left: -.75rem;
    padding: .75rem; }
  .builds__filter-section:nth-child(3) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem; }
  .builds__filter-section:nth-child(4) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1rem;
    padding-bottom: 3rem;
    padding-top: 1rem; }
  .builds__filter-search-wrap {
    position: relative; }
  .builds__filter-search-input {
    appearance: none;
    background: transparent;
    border: none !important;
    color: #ffffff; }
    .builds__filter-search-input:-ms-input-placeholder {
      color: #ffffff; }
    .builds__filter-search-input::placeholder {
      color: #ffffff; }
  .builds__filter-search-submit {
    height: 2rem;
    width: 2rem;
    background: url("/icons/filter-search.svg") no-repeat center;
    background-size: 2rem;
    position: absolute;
    top: 50%;
    right: .75rem;
    transform: translateY(-50%); }
  @media screen and (min-width: 48em) {
    .builds__filter {
      max-width: 60rem;
      margin-bottom: 0; }
      .builds__filter-section {
        -ms-flex: 1;
        flex: 1; }
        .builds__filter-section:nth-child(2) {
          padding-right: 1.5rem; }
        .builds__filter-section:nth-child(3) {
          border-bottom: none;
          border-left: 1px solid rgba(255, 255, 255, 0.2);
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          border-top: none;
          margin-bottom: 0;
          margin-top: 0;
          padding-bottom: 0;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          padding-top: 0; }
        .builds__filter-section:nth-child(4) {
          border-bottom: none;
          border-top: none;
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          margin-bottom: 0;
          margin-top: 0;
          padding-bottom: 0;
          padding-left: 1.5rem;
          padding-right: 2.25rem;
          padding-top: 0; }
        .builds__filter-section:nth-child(5) {
          padding-left: 1.5rem; }
        .builds__filter-section .builds__filter-search-wrap {
          margin-left: -.6rem; } }

.builds__grid {
  display: -ms-grid;
  display: grid;
  gap: 3rem 1.5rem;
  -ms-grid-columns: (1fr)[1];
  grid-template-columns: repeat(1, 1fr); }

.builds__item {
  opacity: 0;
  transition: 0.75s opacity ease-in-out; }
  .builds__item.in-view {
    opacity: 1; }
  .builds__item-main {
    height: 0;
    overflow: hidden;
    padding-bottom: 60%;
    position: relative;
    width: 80%; }
    .builds__item-main img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .builds__item-main img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .builds__item-main-label {
      background: rgba(0, 0, 0, 0.8);
      padding: .5rem .75rem;
      position: absolute;
      bottom: 0;
      left: 0; }
  .builds__item-thumbnails {
    display: -ms-flexbox;
    display: flex;
    margin-left: 1rem;
    margin-right: 0;
    width: calc(20% - 1rem); }
  .builds__item-thumbnail {
    height: 0;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;
    width: 100%; }
    .builds__item-thumbnail img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .builds__item-thumbnail img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .builds__item-thumbnail:not(:last-child) {
      margin-bottom: 1rem; }
  .builds__item--subheading {
    max-height: 0;
    overflow: hidden;
    transition: .25s 0s; }
    .builds__item--subheading p {
      max-width: 0;
      transition: .25s .25s; }
  .builds__item:hover .builds__item--subheading {
    max-height: 4rem;
    transition: .25s .25s; }
    .builds__item:hover .builds__item--subheading p {
      max-width: 25rem;
      transition: .25s 0s; }

.builds__range {
  margin-top: 1rem; }
  .builds__range .noUi-connect {
    background: #86D1D4; }
  .builds__range .noUi-handle {
    border-radius: 50%;
    box-shadow: none;
    height: 23px !important;
    width: 23px !important; }
    .builds__range .noUi-handle::before, .builds__range .noUi-handle::after {
      content: none; }
    .builds__range .noUi-handle .noUi-tooltip {
      bottom: -145%;
      font-size: .8rem;
      padding: 1px 3px; }
  .builds__range.noUi-horizontal {
    height: 12px; }

@media screen and (min-width: 48em) {
  .builds__header {
    margin-bottom: 4.5rem;
    position: relative; }
    .builds__header-text {
      padding-right: 3rem;
      width: 50%; }
      .builds__header-text h1 {
        font-size: 3rem; }
    .builds__header-map {
      margin: 0;
      position: absolute;
      top: 0;
      right: -1rem;
      width: 55%; }
  .builds__grid {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr); } }

@media screen and (min-width: 62em) {
  .builds {
    margin-top: 0;
    padding-top: 12rem; } }

@media screen and (min-width: 82rem) {
  .builds__header-text h1 {
    font-size: 3.6rem; }
  .builds__header-map {
    top: -1.5rem;
    right: -6%;
    width: 64%; } }

@media screen and (min-width: 86rem) {
  .builds__header-map {
    right: -7.5%;
    width: 64%; } }

.cards__heading {
  margin: 0 auto 3rem;
  opacity: 0;
  max-width: 42rem;
  transition: 0.75s opacity ease-in-out; }
  .cards__heading.in-view {
    opacity: 1; }

.cards__item {
  background: #303030;
  margin-bottom: 2rem;
  opacity: 0;
  padding: 1.5rem;
  transform: translate3d(0, 1.5rem, 0);
  transition: 0.75s opacity ease-in-out, 0.75s transform ease-in-out; }
  .cards__item:nth-child(2) {
    transition-delay: 0.25s; }
  .cards__item:nth-child(3) {
    transition-delay: 0.5s; }
  .cards__item:nth-child(4) {
    transition-delay: 0.75s; }
  .cards__item:nth-child(5) {
    transition-delay: 1s; }
  .cards__item:nth-child(6) {
    transition-delay: 1.25s; }
  .cards__item:nth-child(7) {
    transition-delay: 1.5s; }
  .cards__item:nth-child(8) {
    transition-delay: 1.75s; }
  .cards__item:nth-child(9) {
    transition-delay: 2s; }
  .cards__item:nth-child(10) {
    transition-delay: 2.25s; }
  .cards__item.in-view {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .cards__item p:not(:last-child), .cards__item h1:not(:last-child), .cards__item h2:not(:last-child), .cards__item h3:not(:last-child), .cards__item h4:not(:last-child), .cards__item h5:not(:last-child), .cards__item h6:not(:last-child) {
    margin-bottom: .75rem; }

.cards.no-card-background .cards__item {
  background: transparent;
  padding: 0; }

.cards.checkbox-cards .cards__item {
  padding-left: 2rem;
  position: relative; }
  .cards.checkbox-cards .cards__item::before {
    height: 1.25rem;
    width: 1.25rem;
    background: url("/icons/teal-check.svg") no-repeat center;
    background-size: contain;
    content: '';
    position: absolute;
    top: 0;
    left: 0; }

.cards.checkbox-cards .cards__wrapper {
  gap: 3rem 1.5rem !important; }

@media screen and (min-width: 48em) {
  .cards__item {
    margin-bottom: 0; }
  .cards__wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    gap: 1.5rem; }
    .cards__wrapper.two-across, .cards__wrapper.four-across {
      -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr); }
    .cards__wrapper.three-across {
      -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr); }
  .cards.no-card-background .cards__wrapper {
    gap: 3rem; } }

@media screen and (min-width: 62em) {
  .cards__wrapper.four-across {
    -ms-grid-columns: (1fr)[4];
    grid-template-columns: repeat(4, 1fr); } }

.card-slider__left-content {
  max-width: 24em;
  margin-bottom: 2em;
  padding-bottom: 3rem;
  position: relative;
  z-index: 3; }

.card-slider-slider {
  display: -ms-flexbox;
  display: flex; }
  .card-slider-slider__cell {
    background: #f7f8f9;
    margin-right: 1.5rem;
    position: relative;
    overflow: hidden;
    width: 90%; }
    .card-slider-slider__cell-image {
      overflow: hidden;
      padding-bottom: 65%;
      position: relative; }
      .card-slider-slider__cell-image img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .card-slider-slider__cell-image img {
            height: 100%;
            object-fit: cover;
            object-position: center; } }
    .card-slider-slider__cell-text {
      padding: 1.5rem 1rem; }
      .card-slider-slider__cell-text h1, .card-slider-slider__cell-text h2, .card-slider-slider__cell-text h3, .card-slider-slider__cell-text h4, .card-slider-slider__cell-text h5, .card-slider-slider__cell-text h6, .card-slider-slider__cell-text p {
        margin-bottom: .75rem; }

.card-slider .flickity-viewport {
  overflow: visible;
  position: relative;
  width: 100%; }
  .card-slider .flickity-viewport::after {
    background: #ffffff;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    right: 100%;
    width: 100vw; }

.card-slider .flickity-button {
  background-color: #86D1D4;
  top: auto;
  bottom: 0; }
  .card-slider .flickity-button.previous {
    left: -11rem; }
  .card-slider .flickity-button.next {
    left: -6rem;
    right: 0; }
  .card-slider .flickity-button .flickity-button-icon {
    fill: #ffffff; }
  .card-slider .flickity-button.flickity-prev-next-button {
    transform: translateY(0); }
  @media screen and (max-width: 61.999em) {
    .card-slider .flickity-button {
      bottom: 0;
      top: -4rem; }
      .card-slider .flickity-button.previous {
        left: auto;
        right: 5rem; }
      .card-slider .flickity-button.next {
        left: auto;
        right: 0; } }

@media screen and (min-width: 48em) {
  .card-slider-slider {
    -ms-flex: 1;
    flex: 1; }
    .card-slider-slider:focus {
      box-shadow: none !important; }
  .card-slider-slider__cell {
    width: 40%; }
  .card-slider .flickity-viewport:focus,
  .card-slider .flickity-slider:focus {
    box-shadow: none !important; } }

@media screen and (min-width: 62em) {
  .card-slider__left-content {
    padding-right: 3rem; } }

.card-slider + .layout.card-slider .contain {
  border-top: 2px solid #86D1D4;
  padding-top: 3rem;
  margin-bottom: 3rem; }

.contact-cta__wrapper {
  padding: 1.5rem;
  position: relative; }

@media screen and (min-width: 48em) {
  .contact-cta__wrapper {
    padding: 3rem; } }

@media screen and (min-width: 62em) {
  .contact-cta__wrapper {
    padding: 4.5rem;
    padding-right: 9rem; }
  .contact-cta__main {
    padding-right: 3rem; }
    .contact-cta__main h3 {
      margin-bottom: 0;
      max-width: 34rem; }
  .contact-cta .button.ghost {
    font-size: 1.125rem;
    padding: 1rem 3rem; } }

/**
 * Exploding house styles
 *
 * Includes static styles and animation
 */
.exploding-house-container {
  color: #909193;
  display: block;
  margin: 0 auto;
  max-height: 700px;
  max-width: 700px;
  overflow: visible;
  position: relative;
  width: 55vw;
  height: 75vw;
  width: 75vw;
  margin: 0 auto; }
  @media screen and (min-width: 48em) {
    .exploding-house-container {
      height: 100vw;
      width: 100vw; } }
  .exploding-house-container img {
    height: auto;
    position: absolute; }

div.exploding-house {
  position: relative;
  z-index: 1; }

.exploding-house.panel-00 {
  top: 37.25%;
  left: 29%;
  width: 55.7%; }

.exploding-house.panel-01 {
  top: 21.5%;
  left: 54.75%;
  width: 30%; }

.exploding-house.panel-02 {
  top: 21.15%;
  left: 29%;
  width: 26.5%; }

.exploding-house.panel-03 {
  top: 25.5%;
  left: 44.25%;
  width: 21.5%; }

.exploding-house.panel-04 {
  top: 35%;
  left: 30.25%;
  width: 35%; }

.exploding-house.panel-05 {
  top: 28.95%;
  left: 28.5%;
  width: 30%; }

.exploding-house.panel-06 {
  top: 37.75%;
  left: 20.75%;
  width: 37%; }

.exploding-house.panel-07 {
  top: 35.5%;
  left: 77.15%;
  width: 7.75%; }

.exploding-house.panel-08 {
  top: 37.5%;
  left: 71%;
  width: 6.85%; }

.exploding-house.panel-09 {
  top: 39.15%;
  left: 65%;
  width: 7.1%; }

.exploding-house.panel-10 {
  top: 41.15%;
  left: 58.25%;
  width: 7.25%; }

.exploding-house.panel-11 {
  top: 18.5%;
  left: 39.8%;
  width: 20.5%; }

.exploding-house.panel-12 {
  top: 22.2%;
  left: 26.1%;
  width: 20.5%; }

.exploding-house.panel-13 {
  top: 22.35%;
  left: 48.5%;
  width: 20.5%; }

.exploding-house.panel-14 {
  top: 26.35%;
  left: 34.4%;
  width: 20.5%; }

.exploding-house.panel-15 {
  top: 26.25%;
  left: 56.75%;
  width: 20.45%; }

.exploding-house.panel-16 {
  top: 30.25%;
  left: 42.5%;
  width: 18.25%; }

.exploding-house.panel-17 {
  top: 30.15%;
  left: 67%;
  width: 18.5%; }

.exploding-house-container.animate .panel-00 {
  animation: panel-00 4s linear 1; }

.exploding-house-container.animate .panel-01 {
  animation: panel-01 4s linear 1; }

.exploding-house-container.animate .panel-02 {
  animation: panel-02 4s linear 1; }

.exploding-house-container.animate .panel-03 {
  animation: panel-03 4s linear 1; }

.exploding-house-container.animate .panel-04 {
  animation: panel-04 4s linear 1; }

.exploding-house-container.animate .panel-05 {
  animation: panel-05 4s linear 1; }

.exploding-house-container.animate .panel-06 {
  animation: panel-06 4s linear 1; }

.exploding-house-container.animate .panel-10 {
  animation: panel-10 4s linear 1; }

.exploding-house-container.animate .panel-09 {
  animation: panel-09 3.3s linear 1 0.35s; }

.exploding-house-container.animate .panel-08 {
  animation: panel-08 2.6s linear 1 0.7s; }

.exploding-house-container.animate .panel-07 {
  animation: panel-07 1.9s linear 1 1.05s; }

.exploding-house-container.animate .panel-11 {
  animation: panel-11 4s linear 1; }

.exploding-house-container.animate .panel-12 {
  animation: panel-12 3.6s linear 1 0.2s; }

.exploding-house-container.animate .panel-13 {
  animation: panel-13 3.2s linear 1 0.4s; }

.exploding-house-container.animate .panel-14 {
  animation: panel-14 2.8s linear 1 0.6s; }

.exploding-house-container.animate .panel-15 {
  animation: panel-15 2.4s linear 1 0.8s; }

.exploding-house-container.animate .panel-16 {
  animation: panel-16 2s linear 1 1s; }

.exploding-house-container.animate .panel-17 {
  animation: panel-17 1.6s linear 1 1.2s; }

.exploding-house-container.paused .exploding-house {
  animation-play-state: paused; }

/*
 * Animations
 */
@keyframes panel-00 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(0, 10%, 0); }
  80% {
    transform: translate3d(0, 10%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-01 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(15%, -25%, 0); }
  80% {
    transform: translate3d(15%, -25%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-02 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-35%, -15%, 0); }
  80% {
    transform: translate3d(-35%, -15%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-03 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-5%, -15%, 0); }
  80% {
    transform: translate3d(-5%, -15%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-04 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(0%, -35%, 0); }
  80% {
    transform: translate3d(0%, -35%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-05 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-40%, 40%, 0); }
  80% {
    transform: translate3d(-40%, 40%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-06 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-50%, 70%, 0); }
  80% {
    transform: translate3d(-50%, 70%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-07 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(75%, 10%, 0); }
  80% {
    transform: translate3d(75%, 10%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-08 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(55%, 30%, 0); }
  80% {
    transform: translate3d(55%, 30%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-09 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(35%, 50%, 0); }
  80% {
    transform: translate3d(35%, 50%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-10 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(15%, 70%, 0); }
  80% {
    transform: translate3d(15%, 70%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-11 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(5%, -135%, 0); }
  80% {
    transform: translate3d(5%, -135%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-12 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-30%, -115%, 0); }
  80% {
    transform: translate3d(-30%, -115%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-13 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(15%, -115%, 0); }
  80% {
    transform: translate3d(15%, -115%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-14 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-15%, -105%, 0); }
  80% {
    transform: translate3d(-15%, -105%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-15 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(25%, -110%, 0); }
  80% {
    transform: translate3d(25%, -110%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-16 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(0, -130%, 0); }
  80% {
    transform: translate3d(0, -130%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-17 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(40%, -95%, 0); }
  80% {
    transform: translate3d(40%, -95%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.featured-news {
  background-color: #363535;
  padding: 3rem 0; }
  .featured-news .flickity-button {
    left: unset;
    top: -4.5rem; }
    .featured-news .flickity-button.previous {
      right: 3.5rem; }
    .featured-news .flickity-button.next {
      background-color: #86D1D4;
      right: 0; }
    .featured-news .flickity-button .flickity-button-icon {
      fill: #000000; }
    .featured-news .flickity-button.flickity-prev-next-button {
      transform: translateY(0); }
    @media screen and (max-width: 61.999em) {
      .featured-news .flickity-button {
        top: -4rem; } }
  .featured-news .flickity-viewport {
    position: relative;
    width: 100%; }
  .featured-news__slider {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 1.5rem;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .featured-news__cell {
    margin-right: 1.5rem;
    overflow: hidden;
    width: 100%; }
    .featured-news__cell-text {
      background-color: #fff;
      color: #000;
      height: 100%;
      padding: 1.5rem; }
      .featured-news__cell-text h3 {
        color: #000;
        font-family: "Poppins", "Helvetica", sans-serif;
        font-weight: 700; }
      .featured-news__cell-text .layout:first-of-type {
        margin-top: 1.5rem; }
        .featured-news__cell-text .layout:first-of-type .contain {
          padding: 0; }
  .featured-news__heading {
    margin-bottom: 1.5rem;
    opacity: 0;
    padding-bottom: 3rem;
    position: relative;
    transition: 0.75s opacity ease-in-out; }
    .featured-news__heading.in-view {
      opacity: 1; }
    .featured-news__heading p {
      font-size: 1.75rem; }
    .featured-news__heading-link {
      color: #86D1D4;
      position: absolute;
      bottom: 0.25rem;
      right: 8rem; }
  .featured-news__image {
    height: 0;
    overflow: hidden;
    padding-bottom: calc(100% * 2 / 3);
    position: relative; }
    .featured-news__image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .featured-news__image img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  @media screen and (max-width: 47.999em) {
    .featured-news__cell-text h3 {
      font-size: 1.375rem; } }
  @media screen and (min-width: 48em) {
    .featured-news__cell {
      width: calc((100% / 3) - 0.75rem); }
    .featured-news__heading {
      padding-bottom: 0;
      padding-right: 15rem; }
      .featured-news__heading-link {
        bottom: .75rem; }
    .featured-news__slider {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; } }

.featured-builds__heading {
  margin-bottom: 1.5rem;
  opacity: 0;
  transition: 0.75s opacity ease-in-out; }
  .featured-builds__heading.in-view {
    opacity: 1; }
  .featured-builds__heading .arrow-link {
    padding-right: 3rem; }
    .featured-builds__heading .arrow-link::after {
      height: 2rem;
      width: 2rem; }

.featured-builds__wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[1];
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem; }

.featured-builds__img {
  height: 0;
  overflow: hidden;
  padding-bottom: 79%;
  position: relative; }
  .featured-builds__img img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .featured-builds__img img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.featured-builds__item {
  opacity: 0;
  transform: translate3d(0, 1.5rem, 0);
  transition: 0.75s opacity ease-in-out, 0.75s transform ease-in-out; }
  .featured-builds__item:nth-child(2) {
    transition-delay: 0.25s; }
  .featured-builds__item:nth-child(3) {
    transition-delay: 0.5s; }
  .featured-builds__item.in-view {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

@media screen and (min-width: 48em) {
  .featured-builds__wrapper {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr); } }

/**
 * Flex Blocks Layout
 * DBS>Interactive
 */
.flex-blocks .cell {
  padding-top: 1em;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .flex-blocks .cell {
      width: 50%; } }
  @media screen and (min-width: 62em) {
    .flex-blocks .cell {
      width: 33.33%; } }

.form-layout .freeform-form-container {
  background: #303030;
  border-radius: 1.25rem;
  padding: 1rem; }
  @media screen and (min-width: 48em) {
    .form-layout .freeform-form-container {
      padding: 1.5rem; } }
  .form-layout .freeform-form-container .freeform-row .floating .freeform-input {
    background: transparent;
    border: none;
    border-bottom: 1px solid #ffffff;
    border-radius: 0;
    color: #ffffff !important; }
    .form-layout .freeform-form-container .freeform-row .floating .freeform-input:-ms-input-placeholder {
      color: #ffffff; }
    .form-layout .freeform-form-container .freeform-row .floating .freeform-input::placeholder {
      color: #ffffff; }
    .form-layout .freeform-form-container .freeform-row .floating .freeform-input:-webkit-autofill, .form-layout .freeform-form-container .freeform-row .floating .freeform-input:-webkit-autofill:hover, .form-layout .freeform-form-container .freeform-row .floating .freeform-input:-webkit-autofill:focus, .form-layout .freeform-form-container .freeform-row .floating .freeform-input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #303030 inset !important;
      -webkit-text-fill-color: #ffffff !important; }
    .form-layout .freeform-form-container .freeform-row .floating .freeform-input + .freeform-label {
      background: transparent !important;
      color: #ffffff !important;
      font-family: "Poppins", "Helvetica", sans-serif;
      font-weight: 600;
      opacity: 1 !important;
      transform: .25s !important; }
      .form-layout .freeform-form-container .freeform-row .floating .freeform-input + .freeform-label::after {
        color: #ffffff !important; }
  .form-layout .freeform-form-container .freeform-row .floating textarea.freeform-input {
    background: #232323;
    border: 1px solid #595959;
    border-radius: .5rem; }
  .form-layout .freeform-form-container .freeform-fieldtype-select.floating .freeform-label,
  .form-layout .freeform-form-container .freeform-fieldtype-dropdown.floating .freeform-label {
    background: transparent;
    color: #ffffff !important;
    font-family: "Poppins", "Helvetica", sans-serif;
    font-size: 1rem;
    font-weight: 600;
    opacity: 1 !important;
    padding-left: 1rem;
    position: static; }
  .form-layout .freeform-form-container .freeform-fieldtype-select.floating .freeform-input,
  .form-layout .freeform-form-container .freeform-fieldtype-dropdown.floating .freeform-input {
    background: url("/icons/arrow-down-white.svg") no-repeat calc(100% - 1rem) center;
    background-color: #232323;
    background-size: 1.5rem;
    border: 1px solid #595959;
    appearance: none;
    border-radius: .5rem;
    font-family: "Poppins", "Helvetica", sans-serif;
    padding: 1rem; }

.form-layout .freeform-fieldtype-checkbox,
.form-layout .freeform-fieldtype-checkbox_group,
.form-layout .freeform-fieldtype-checkboxes,
.form-layout .freeform-fieldtype-mailing_list {
  display: -ms-flexbox;
  display: flex; }
  .form-layout .freeform-fieldtype-checkbox .freeform-label,
  .form-layout .freeform-fieldtype-checkbox label,
  .form-layout .freeform-fieldtype-checkbox_group .freeform-label,
  .form-layout .freeform-fieldtype-checkbox_group label,
  .form-layout .freeform-fieldtype-checkboxes .freeform-label,
  .form-layout .freeform-fieldtype-checkboxes label,
  .form-layout .freeform-fieldtype-mailing_list .freeform-label,
  .form-layout .freeform-fieldtype-mailing_list label {
    color: #ffffff;
    font-family: "Poppins", "Helvetica", sans-serif;
    font-weight: 700 !important; }
  .form-layout .freeform-fieldtype-checkbox .freeform-input,
  .form-layout .freeform-fieldtype-checkbox_group .freeform-input,
  .form-layout .freeform-fieldtype-checkboxes .freeform-input,
  .form-layout .freeform-fieldtype-mailing_list .freeform-input {
    appearance: none;
    background: #232323;
    border: 1px solid #595959;
    border-radius: .5rem;
    height: 1.5rem !important;
    padding: 0 !important;
    width: 1.5rem !important;
    min-width: 1.5rem; }
    .form-layout .freeform-fieldtype-checkbox .freeform-input:checked,
    .form-layout .freeform-fieldtype-checkbox_group .freeform-input:checked,
    .form-layout .freeform-fieldtype-checkboxes .freeform-input:checked,
    .form-layout .freeform-fieldtype-mailing_list .freeform-input:checked {
      background-image: url("/icons/check.svg");
      background-size: 90%;
      background-position: center;
      background-repeat: no-repeat; }

.form-layout .basic-radio {
  color: #ffffff;
  font-family: "Poppins", "Helvetica", sans-serif;
  font-weight: 700 !important;
  margin-bottom: .5rem !important; }
  .form-layout .basic-radio ~ label,
  .form-layout .basic-radio ~ .input-group-one-line label {
    color: #ffffff;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
    font-family: "Poppins", "Helvetica", sans-serif;
    margin-bottom: .5rem !important; }
    .form-layout .basic-radio ~ label .freeform-input,
    .form-layout .basic-radio ~ .input-group-one-line label .freeform-input {
      appearance: none;
      background: #232323;
      border: 1px solid #595959;
      border-radius: .5rem;
      height: 1.5rem !important;
      padding: 0 !important;
      width: 1.5rem !important;
      min-width: 1.5rem; }
      .form-layout .basic-radio ~ label .freeform-input:checked,
      .form-layout .basic-radio ~ .input-group-one-line label .freeform-input:checked {
        background-image: url("/icons/check.svg");
        background-size: 90%;
        background-position: center;
        background-repeat: no-repeat; }
  .form-layout .basic-radio ~ .input-group-one-line label:first-child {
    padding-right: 1.5rem; }

.form-layout .freeform-fieldtype-checkbox_group,
.form-layout .freeform-fieldtype-checkboxes {
  display: block; }
  .form-layout .freeform-fieldtype-checkbox_group label,
  .form-layout .freeform-fieldtype-checkboxes label {
    display: -ms-flexbox !important;
    display: flex !important;
    margin-bottom: .75rem !important; }
  .form-layout .freeform-fieldtype-checkbox_group .freeform-input:checked,
  .form-layout .freeform-fieldtype-checkboxes .freeform-input:checked {
    background-image: url("/icons/check.svg");
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat; }

.form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group,
.form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radio_group,
.form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radios,
.form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkboxes,
.form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group,
.form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group,
.form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-family: "Poppins", "Helvetica", sans-serif;
  margin-left: -1rem !important;
  margin-right: -1rem !important;
  width: calc(100% + 2rem); }
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group .freeform-label:first-child,
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radio_group .freeform-label:first-child,
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radios .freeform-label:first-child,
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkboxes .freeform-label:first-child,
  .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group .freeform-label:first-child,
  .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .freeform-label:first-child,
  .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .freeform-label:first-child {
    color: #ffffff;
    display: block !important;
    font-weight: bold;
    margin-bottom: 2rem !important;
    margin-top: 3rem;
    padding-bottom: 1rem;
    position: relative;
    text-align: center;
    width: 100%; }
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group .freeform-label:first-child:before,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radio_group .freeform-label:first-child:before,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radios .freeform-label:first-child:before,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkboxes .freeform-label:first-child:before,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group .freeform-label:first-child:before,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .freeform-label:first-child:before,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .freeform-label:first-child:before {
      border-bottom: 1px solid #86D1D4;
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 3rem; }
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group .freeform-label:first-child::after,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radio_group .freeform-label:first-child::after,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radios .freeform-label:first-child::after,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkboxes .freeform-label:first-child::after,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group .freeform-label:first-child::after,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .freeform-label:first-child::after,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .freeform-label:first-child::after {
      color: #ffffff !important; }
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group label:not(:first-child),
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radio_group label:not(:first-child),
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radios label:not(:first-child),
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkboxes label:not(:first-child),
  .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group label:not(:first-child),
  .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group label:not(:first-child),
  .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group label:not(:first-child) {
    box-shadow: 0px 0.25rem 1rem 0px rgba(0, 0, 0, 0.25);
    padding: .75rem .75rem 6rem;
    padding-bottom: 6rem;
    color: #ffffff;
    font-size: .9rem;
    font-family: "Noto Serif", serif;
    font-weight: 700;
    margin: 0 .5rem 1.5rem;
    position: relative;
    width: calc(50% - 1rem); }
    @media screen and (min-width: 48em) {
      .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group label:not(:first-child),
      .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radio_group label:not(:first-child),
      .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radios label:not(:first-child),
      .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkboxes label:not(:first-child),
      .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group label:not(:first-child),
      .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group label:not(:first-child),
      .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group label:not(:first-child) {
        font-size: 1rem;
        margin: 0 1rem 1.5rem;
        width: calc(50% - 2rem); } }
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group .model-checkbox,
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radio_group .model-checkbox,
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radios .model-checkbox,
  .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkboxes .model-checkbox,
  .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group .model-checkbox,
  .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .model-checkbox,
  .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .model-checkbox {
    appearance: none !important;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer;
    height: 100% !important;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: none !important;
    width: 100% !important; }
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group .model-checkbox:checked,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radio_group .model-checkbox:checked,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radios .model-checkbox:checked,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkboxes .model-checkbox:checked,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group .model-checkbox:checked,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .model-checkbox:checked,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .model-checkbox:checked {
      border: 1px solid #86D1D4 !important; }
      .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group .model-checkbox:checked::before,
      .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radio_group .model-checkbox:checked::before,
      .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radios .model-checkbox:checked::before,
      .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkboxes .model-checkbox:checked::before,
      .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group .model-checkbox:checked::before,
      .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .model-checkbox:checked::before,
      .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .model-checkbox:checked::before {
        height: 1.5rem;
        width: 1.5rem;
        background-image: url("/icons/check.svg");
        background-size: 90%;
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        position: absolute;
        top: .5rem;
        right: .75rem; }
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group .model-checkbox:focus,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radio_group .model-checkbox:focus,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radios .model-checkbox:focus,
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkboxes .model-checkbox:focus,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group .model-checkbox:focus,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .model-checkbox:focus,
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group .model-checkbox:focus {
      box-shadow: none !important; }
  @media screen and (min-width: 48em) {
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkbox_group label:not(:first-child),
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radio_group label:not(:first-child),
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-radios label:not(:first-child),
    .form-layout .contact-form .freeform-row:nth-child(13) .freeform-fieldtype-checkboxes label:not(:first-child),
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-checkbox_group label:not(:first-child),
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group label:not(:first-child),
    .form-layout .contact-form .freeform-row:nth-child(14) .freeform-fieldtype-radio_group label:not(:first-child) {
      width: calc(25% - 2rem); } }

.form-layout .contact-form #form-input-model_checkboxes-0 {
  background: url("/images/kits/modern_wo_trees_800.png") no-repeat right bottom; }

.form-layout .contact-form #form-input-model_checkboxes-1 {
  background: url("/images/kits/cottage_wo_trees_800.png") no-repeat right bottom; }

.form-layout .contact-form #form-input-model_checkboxes-2 {
  background: url("/images/kits/contemporary_wo_trees_800.png") no-repeat right bottom; }

.form-layout .contact-form #form-input-model_checkboxes-3 {
  background: url("/images/kits/carriage_wo_trees_800.png") no-repeat right bottom; }

.form-layout .contact-form #form-input-model_checkboxes-4 {
  background: url("/images/kits/ranch_wo_trees_800.png") no-repeat right bottom; }

.form-layout .contact-form #form-input-model_checkboxes-5 {
  background: url("/images/kits/duplex_wo_trees_800.png") no-repeat right bottom; }

.form-layout .contact-form #form-input-model_checkboxes-6 {
  background: url("/images/kits/a-frame_wo_trees_800.png") no-repeat right bottom; }

.form-layout .contact-form #form-input-model_checkboxes-0,
.form-layout .contact-form #form-input-model_checkboxes-1,
.form-layout .contact-form #form-input-model_checkboxes-2,
.form-layout .contact-form #form-input-model_checkboxes-3,
.form-layout .contact-form #form-input-model_checkboxes-4,
.form-layout .contact-form #form-input-model_checkboxes-5,
.form-layout .contact-form #form-input-model_checkboxes-6 {
  background-size: auto 44%; }
  @media screen and (min-width: 48em) {
    .form-layout .contact-form #form-input-model_checkboxes-0,
    .form-layout .contact-form #form-input-model_checkboxes-1,
    .form-layout .contact-form #form-input-model_checkboxes-2,
    .form-layout .contact-form #form-input-model_checkboxes-3,
    .form-layout .contact-form #form-input-model_checkboxes-4,
    .form-layout .contact-form #form-input-model_checkboxes-5,
    .form-layout .contact-form #form-input-model_checkboxes-6 {
      background-size: auto 70%; } }

.form-layout .freeform-form button.button {
  background-color: #ffffff !important;
  color: #376A72 !important; }
  .form-layout .freeform-form button.button:hover, .form-layout .freeform-form button.button:focus, .form-layout .freeform-form button.button:active {
    background-color: #376A72 !important;
    color: #ffffff !important; }

/**
 * Full Width Flex Layout
 * DBS>Interactive
 *
 * This layout takes up the full width of the browser and may have a background image.
 */
.hover-cards__grid {
  display: -ms-grid;
  display: grid;
  gap: 1.5rem;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto; }

.hover-cards__item {
  position: relative;
  display: inline-block;
  /* Make the width of box same as image */ }
  .hover-cards__item--front {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transition: .35s; }
    .hover-cards__item--front:after {
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(55, 57, 59, 0) 30%, #1d2e34 100%);
      content: '';
      opacity: 0.9;
      position: absolute;
      top: 0;
      left: 0; }
    .hover-cards__item--front img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      transition: .35s; }
      @supports (object-fit: contain) {
        .hover-cards__item--front img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .hover-cards__item--footer {
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 1.5rem; }
    .hover-cards__item--footer h3 {
      font-weight: bold;
      font-size: 1.25rem; }
    .hover-cards__item--footer h3, .hover-cards__item--footer .arrow-link {
      color: #ffffff; }
  .hover-cards__item-text {
    opacity: 0; }

@media screen and (max-width: 47.999em) {
  .hover-cards__item {
    max-height: 13rem;
    overflow: hidden;
    width: 100%; }
    .hover-cards__item--text {
      height: 13rem;
      overflow: hidden; } }

@media screen and (min-width: 48em) {
  .hover-cards__grid {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr); }
  .hover-cards__item {
    height: 100%;
    min-height: 20rem; }
    .hover-cards__item--footer {
      display: -ms-flexbox;
      display: flex; }
    .hover-cards__item--text {
      color: #ffffff;
      background: linear-gradient(90deg, #04424F 10%, #1d2e34 100%);
      height: 100%;
      opacity: 0;
      padding: 1.5rem;
      position: relative;
      transition: opacity .25s;
      z-index: 2; }
      .hover-cards__item--text p {
        margin-bottom: 1rem; }
      .hover-cards__item--text ul {
        margin: 0; }
    .hover-cards__item--front::after {
      background: linear-gradient(180deg, rgba(55, 57, 59, 0) 50%, #1d2e34 90%); }
    .hover-cards__item:hover .hover-cards__item--front img {
      opacity: 0; }
    .hover-cards__item:hover .hover-cards__item--text {
      opacity: 1; } }

.how-it-works .animation-trigger.in-view ~ .how-it-works__left {
  opacity: 1; }

.how-it-works__left {
  margin-bottom: 1.5rem;
  opacity: 0;
  transition: 0.75s opacity ease-in-out; }
  .how-it-works__left-top {
    margin-bottom: 1.5rem; }

.how-it-works__cta-link-wrapper {
  padding-right: 1.5rem;
  width: 60%; }
  .how-it-works__cta-link-wrapper a {
    width: 100%; }

.how-it-works__cta-image {
  margin-left: auto;
  width: 40%; }

.how-it-works__cta:first-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.45);
  margin-bottom: 1rem;
  padding-bottom: 1rem; }

.how-it-works:first-child .how-it-works__left {
  animation: opacity 0.75s ease-in-out both;
  opacity: 1; }

@media screen and (min-width: 48em) {
  .how-it-works__left {
    margin-bottom: 0;
    padding-right: 3rem; }
  .how-it-works__right {
    padding-left: 3rem; } }

.kits-layout__heading,
.kits-layout .button {
  opacity: 0;
  transition: 0.75s opacity ease-in-out, background 0.25s, color 0.25s; }

.kits-layout .button {
  transition-delay: 1.4s; }

.kits-layout__wrapper {
  margin-bottom: 1.5rem; }

.kits-layout__kit {
  margin-right: 2rem;
  width: calc(50% - 2rem); }
  .kits-layout__kit-image {
    height: 0;
    margin-bottom: .75rem;
    overflow: hidden;
    padding-bottom: 60%;
    position: relative; }
    .kits-layout__kit-image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .kits-layout__kit-image img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }

.kits-layout .animation-trigger.in-view ~ .kits-layout__heading,
.kits-layout .animation-trigger.in-view ~ p .button {
  opacity: 1; }

.kits-layout .flickity-button {
  background: url("/icons/slider-arrow.svg") no-repeat center !important;
  background-size: 100%;
  opacity: .25;
  transition: .25s opacity;
  transform: none; }
  .kits-layout .flickity-button.previous {
    background-image: url("/icons/slider-arrow-back.svg") !important;
    left: -1rem; }
  .kits-layout .flickity-button.next {
    right: -1rem; }
  .kits-layout .flickity-button:hover, .kits-layout .flickity-button:focus {
    opacity: 1; }
  .kits-layout .flickity-button .flickity-button-icon {
    display: none; }

@media screen and (min-width: 48em) {
  .kits-layout__kit {
    width: calc(33.33% - 2rem); } }

@media screen and (min-width: 62em) {
  .kits-layout__kit {
    width: calc(17% - 2rem); }
  .kits-layout .flickity-button.previous {
    left: -2rem; }
  .kits-layout .flickity-button.next {
    right: -2rem; } }

.kits-grid {
  padding-bottom: 3rem;
  padding-top: 3rem;
  background: linear-gradient(152deg, #284750 0%, #211F1F 100%);
  margin-top: 0; }
  @media screen and (min-width: 48em) {
    .kits-grid {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; } }
  .kits-grid__heading {
    animation: opacity 1.5s ease-in-out 0s both;
    margin-bottom: 3rem; }
    .kits-grid__heading hr {
      border-color: #86D1D4;
      border-width: 5px;
      margin: 1.5rem auto;
      width: 5rem; }
    .kits-grid__heading p {
      font-size: 1.125rem;
      margin: 0 auto;
      max-width: 34rem; }
  .kits-grid__kit {
    background: #303030;
    margin-bottom: 1.5rem;
    opacity: 0;
    padding: 3rem 0 0 1.5rem;
    transform: translate3d(0, 1.5rem, 0);
    transition: 0.75s opacity ease-in-out, 0.75s transform ease-in-out; }
    .kits-grid__kit.in-view {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .kits-grid__kit-sizes ul {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: start;
      -ms-flex-direction: column;
      flex-direction: column;
      list-style: none;
      margin: 0 0 1.5rem;
      padding: 0; }
      .kits-grid__kit-sizes ul li {
        margin-bottom: .75rem; }
        .kits-grid__kit-sizes ul li a {
          background: #232323;
          display: inline-block;
          padding: .65rem;
          text-decoration: none;
          width: 100%; }
    .kits-grid__kit-image {
      margin-left: auto;
      width: 60%; }
  @media screen and (min-width: 48em) {
    .kits-grid__grid {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
      -ms-grid-rows: auto;
      grid-template-rows: auto;
      gap: 1.5rem; }
    .kits-grid__kit {
      margin-bottom: 0; }
      .kits-grid__kit:nth-child(even) {
        transition-delay: .25s; }
      .kits-grid__kit-sizes ul {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
        -ms-grid-rows: (1fr)[3];
        grid-template-rows: repeat(3, 1fr);
        gap: .75rem; }
        .kits-grid__kit-sizes ul li {
          margin-bottom: 0; }
          .kits-grid__kit-sizes ul li:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2; }
          .kits-grid__kit-sizes ul li:nth-child(2) {
            grid-area: 2 / 1 / 3 / 2; }
          .kits-grid__kit-sizes ul li:nth-child(3) {
            grid-area: 3 / 1 / 4 / 2; }
          .kits-grid__kit-sizes ul li:nth-child(4) {
            grid-area: 1 / 2 / 2 / 3; }
          .kits-grid__kit-sizes ul li:nth-child(5) {
            grid-area: 2 / 2 / 3 / 3; }
          .kits-grid__kit-sizes ul li:nth-child(6) {
            grid-area: 3 / 2 / 4 / 3; } }
  @media screen and (min-width: 62em) {
    .kits-grid {
      padding-top: 12rem; } }

.kit-header__left-main {
  margin-bottom: 2rem; }
  .kit-header__left-main .kit-header__h1 {
    animation: opacity 1.5s ease-in-out 0s both; }
  .kit-header__left-main p {
    animation: opacity 1.5s ease-in-out .25s both; }

.kit-header__left .get-quote-wrapper,
.kit-header__left .kit-header__left-sizes {
  animation: opacity 1.5s ease-in-out .5s both; }

.kit-header__left-sizes {
  margin-bottom: 2rem; }
  .kit-header__left-sizes h3 {
    margin-bottom: .75rem; }
  .kit-header__left-sizes ul {
    padding: 0;
    list-style: none;
    margin: 0; }
    .kit-header__left-sizes ul li {
      border-top: 1px solid rgba(255, 255, 255, 0.2); }

.kit-header__left .kit-header__button {
  padding: 1rem;
  width: 100%; }
  .kit-header__left .kit-header__button.active {
    font-weight: bold;
    position: relative; }
    .kit-header__left .kit-header__button.active::before {
      background: url("/icons/selected-size.svg") no-repeat left center;
      background-size: auto 100%;
      content: '';
      height: 80%;
      position: absolute;
      top: 50%;
      left: -.5rem;
      transform: translateY(-50%);
      width: 1rem; }
    .kit-header__left .kit-header__button.active:focus, .kit-header__left .kit-header__button.active:active {
      box-shadow: none !important; }

.kit-header__gallery {
  padding-bottom: 3rem;
  padding-top: 1.5em;
  position: relative; }
  .kit-header__gallery-wrapper {
    opacity: 0;
    position: absolute;
    visibility: hidden; }
    .kit-header__gallery-wrapper.active {
      opacity: 1;
      position: static;
      visibility: visible; }
  .kit-header__gallery-main {
    animation: opacity 1.5s ease-in-out 0s both;
    margin-bottom: 1rem; }
  .kit-header__gallery .kit-header__gallery-open {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
  .kit-header__gallery-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    max-height: 33rem;
    overflow: auto;
    position: relative; }
    @media screen and (max-width: 47.999em) {
      .kit-header__gallery-nav {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        margin-right: -1.5rem;
        overflow-x: auto; }
        .kit-header__gallery-nav .kit-header__gallery-button {
          animation: opacity 1.5s ease-in-out 0s both;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          margin-right: .25rem;
          padding-bottom: 25%;
          width: 7rem; } }
  .kit-header__gallery-button {
    animation: opacity 1.5s ease-in-out 0s both;
    appearance: none;
    border: none;
    overflow: hidden;
    margin-bottom: .5rem;
    padding-bottom: 65%;
    position: relative; }
    .kit-header__gallery-button img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .kit-header__gallery-button img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .kit-header__gallery-button:nth-child(2) {
      transition-delay: 0.25s; }
    .kit-header__gallery-button:nth-child(3) {
      transition-delay: 0.5s; }
    .kit-header__gallery-button:nth-child(4) {
      transition-delay: 0.75s; }
    .kit-header__gallery-button:nth-child(5) {
      transition-delay: 1s; }
    .kit-header__gallery-button:nth-child(6) {
      transition-delay: 1.25s; }
    .kit-header__gallery-button:nth-child(7) {
      transition-delay: 1.5s; }
    .kit-header__gallery-button:nth-child(8) {
      transition-delay: 1.75s; }
    .kit-header__gallery-button:nth-child(9) {
      transition-delay: 2s; }
    .kit-header__gallery-button:nth-child(10) {
      transition-delay: 2.25s; }
    .kit-header__gallery-button.active {
      box-shadow: none !important;
      cursor: not-allowed;
      opacity: .5; }
  .kit-header__gallery-open {
    background: url("/icons/view-all-dots.svg") no-repeat left;
    background-size: 1.25rem 100%;
    margin-top: .75rem;
    padding-left: 2rem; }

@media screen and (min-width: 48em) {
  .kit-header__left {
    padding-right: 3rem;
    width: 40%; }
  .kit-header__gallery {
    -ms-flex-preferred-size: 65%;
    flex-basis: 65%;
    padding-left: 3rem;
    padding-top: 0; }
    .kit-header__gallery-wrapper {
      display: -ms-flexbox;
      display: flex; }
    .kit-header__gallery-main {
      -ms-flex-preferred-size: 80%;
      flex-basis: 80%;
      margin-bottom: 0;
      padding-right: 1em; }
    .kit-header__gallery-nav {
      -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
      -ms-flex-direction: column;
      flex-direction: column; }
    .kit-header__gallery .kit-header__gallery-open {
      left: auto;
      right: 1.5rem;
      transform: none; }
  .kit-header__right {
    width: 60%; } }

@media screen and (min-width: 62em) {
  .kit-header {
    margin-top: 0;
    padding-top: 12rem; } }

.kit-header-lightbox,
.floorplan-lightbox {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  position: fixed;
  top: 150%;
  left: 0;
  visibility: hidden;
  z-index: 5000; }
  .kit-header-lightbox.open,
  .floorplan-lightbox.open {
    opacity: 1;
    top: 0;
    visibility: visible; }
  .kit-header-lightbox__container,
  .floorplan-lightbox__container {
    background: #ffffff;
    height: calc(100% - 2rem);
    max-height: 100vw;
    max-width: 65rem;
    padding: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 2rem);
    z-index: 5001; }
  .kit-header-lightbox .flickity-viewport,
  .floorplan-lightbox .flickity-viewport {
    width: 100%; }
  .kit-header-lightbox__main,
  .floorplan-lightbox__main {
    height: calc(80% - 2rem);
    margin-bottom: 2rem;
    position: relative;
    width: 100%; }
    .kit-header-lightbox__main-image,
    .floorplan-lightbox__main-image {
      height: 100%;
      width: 100%; }
      .kit-header-lightbox__main-image img,
      .floorplan-lightbox__main-image img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .kit-header-lightbox__main-image img,
          .floorplan-lightbox__main-image img {
            height: 100%;
            object-fit: contain;
            object-position: center; } }
  .kit-header-lightbox__nav,
  .floorplan-lightbox__nav {
    display: -ms-flexbox;
    display: flex;
    height: 20%;
    list-style: none;
    margin: 0 -1rem 0 0;
    overflow: auto;
    padding: 0;
    width: 100%; }
    .kit-header-lightbox__nav button,
    .floorplan-lightbox__nav button {
      appearance: none;
      background: none;
      border: none;
      cursor: pointer;
      display: block;
      margin-bottom: .75rem;
      padding-bottom: 75%;
      position: relative;
      width: 100%; }
    .kit-header-lightbox__nav img,
    .floorplan-lightbox__nav img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .kit-header-lightbox__nav img,
        .floorplan-lightbox__nav img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
  .kit-header-lightbox__close,
  .floorplan-lightbox__close {
    height: 3rem;
    width: 3rem;
    background: #ffffff;
    padding: .65rem;
    position: absolute;
    top: 0;
    right: 0; }
  @media screen and (min-width: 48em) {
    .kit-header-lightbox__container,
    .floorplan-lightbox__container {
      height: calc(100% - 4rem);
      display: -ms-flexbox;
      display: flex;
      max-height: 38rem;
      max-width: 62rem; }
    .kit-header-lightbox__main,
    .floorplan-lightbox__main {
      height: 100%;
      margin-bottom: 0;
      margin-right: 1rem;
      width: calc(85% - 1rem); }
      .kit-header-lightbox__main-image img,
      .floorplan-lightbox__main-image img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .kit-header-lightbox__main-image img,
          .floorplan-lightbox__main-image img {
            height: 100%;
            object-fit: contain;
            object-position: center; } }
    .kit-header-lightbox__nav,
    .floorplan-lightbox__nav {
      -ms-flex-direction: column;
      flex-direction: column;
      height: 100%;
      margin: 0;
      overflow: auto;
      width: 15%; }
      .kit-header-lightbox__nav li,
      .floorplan-lightbox__nav li {
        margin-bottom: 1rem;
        margin-right: 0;
        padding-bottom: 75%;
        width: 100%; }
        .kit-header-lightbox__nav li button,
        .floorplan-lightbox__nav li button {
          display: block;
          padding-bottom: 75%; } }

.kit-includes__columns {
  overflow: 0;
  position: absolute;
  visibility: hidden; }
  .kit-includes__columns.active {
    opacity: 1;
    position: relative;
    visibility: visible; }

.kit-includes__column {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem; }
  .kit-includes__column h4:not(.kit-includes__cta-heading) {
    font-size: 1rem;
    font-family: "Poppins", "Helvetica", sans-serif;
    font-weight: 400; }
  .kit-includes__column ul {
    list-style: initial !important; }

.kit-includes__cta {
  padding: 1.5rem; }
  .kit-includes__cta-image {
    margin: 0 0 -4.75rem; }

@media screen and (min-width: 48em) {
  .kit-includes__columns {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: (1fr)[2];
    grid-template-rows: repeat(2, 1fr);
    gap: 6rem; }
  .kit-includes__column {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0; } }
  @media screen and (min-width: 48em) and (max-width: 61.999em) {
    .kit-includes__column:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2; }
    .kit-includes__column:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3; }
    .kit-includes__column:nth-child(3) {
      grid-area: 2 / 1 / 3 / 3; } }

@media screen and (min-width: 48em) {
    .kit-includes__column:nth-child(1) {
      position: relative; }
      .kit-includes__column:nth-child(1)::after {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        content: '';
        height: 100%;
        position: absolute;
        right: -3rem;
        top: 0; }
  .kit-includes__cta {
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start; }
    .kit-includes__cta-image {
      margin: 0 0 -8.75rem; } }

@media screen and (min-width: 62em) {
  .kit-includes__columns {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr; }
  .kit-includes__cta-image {
    margin: 0 -4.75rem -4.75rem -4.75rem; } }

.kit-slider__left {
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 4; }
  .kit-slider__left hr {
    border-color: #86D1D4;
    margin: 1rem auto 1rem 0;
    width: 4rem; }

.kit-slider__right {
  position: relative; }
  .kit-slider__right::before {
    background: #232323;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    right: 100%;
    width: 100vw;
    z-index: 2; }

.kit-slider__cell {
  background: #363535;
  margin-right: 1rem;
  overflow: hidden;
  padding: 1rem 1rem 0 1rem;
  position: relative;
  width: 60%; }
  .kit-slider__cell-heading {
    padding-bottom: 6rem;
    padding-right: 8rem; }
  .kit-slider__cell-image {
    height: 75%;
    position: absolute;
    bottom: 0;
    right: -1rem;
    width: 83%; }
    .kit-slider__cell-image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .kit-slider__cell-image img {
          height: 100%;
          object-fit: contain;
          object-position: bottom right; } }
  .kit-slider__cell.the-a-frame .kit-slider__cell-image, .kit-slider__cell.the-duplex .kit-slider__cell-image {
    bottom: -1rem;
    height: 90%;
    right: -2rem;
    width: 108%; }
  .kit-slider__cell.the-a-frame .kit-slider__cell-image {
    right: -3rem; }
  .kit-slider__cell.the-modern .kit-slider__cell-image,
  .kit-slider__cell.the-tiny .kit-slider__cell-image {
    width: 110%;
    right: -2.25rem;
    bottom: -.15rem; }
  .kit-slider__cell.the-tiny .kit-slider__cell-image {
    bottom: -1rem; }
  .kit-slider__cell.the-ranch .kit-slider__cell-image {
    bottom: -.5rem;
    width: 90%; }

.kit-slider__buttons .flickity-button.previous {
  margin-right: 1.5rem; }

.kit-slider .flickity-button {
  background: url("icons/slider-arrow.svg") no-repeat center !important;
  background-size: 100%;
  transition: .25s opacity;
  transform: none;
  position: static; }
  .kit-slider .flickity-button.previous {
    background-image: url("icons/slider-arrow-back.svg") !important; }
  .kit-slider .flickity-button:hover, .kit-slider .flickity-button:focus {
    opacity: .7; }

.kit-slider .flickity-viewport {
  overflow: visible; }

@media screen and (min-width: 48em) {
  .kit-slider__left {
    margin-bottom: 0;
    padding-right: 3rem;
    width: 40%; }
  .kit-slider__right {
    width: 60%; }
  .kit-slider__cell {
    width: 33%; } }

@media screen and (min-width: 62em) {
  .kit-slider__left {
    width: 20%; }
  .kit-slider__right {
    width: 80%; }
  .kit-slider__cell {
    width: 25%; } }

.kit-specs {
  padding-bottom: 3rem;
  padding-top: 3rem;
  background: linear-gradient(-20deg, #474747 -10%, #232323 50%);
  margin-bottom: 0 !important;
  margin-top: 0 !important; }
  @media screen and (min-width: 48em) {
    .kit-specs {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; } }
  .kit-specs__floorplans {
    margin-bottom: 3rem;
    overflow: 0;
    position: absolute;
    visibility: hidden; }
    .kit-specs__floorplans.active {
      opacity: 1;
      position: relative;
      visibility: visible; }
  .kit-specs__sizes {
    margin-bottom: 3rem; }
  .kit-specs__size {
    overflow: 0;
    position: absolute;
    visibility: hidden; }
    .kit-specs__size.active {
      opacity: 1;
      position: relative;
      visibility: visible; }
  .kit-specs__section {
    background-size: 2.5rem 2.25rem;
    background-position: left top;
    background-repeat: no-repeat;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    padding-left: 4rem; }
    .kit-specs__section.sq-ft {
      background-image: url("/icons/sq-ft.svg"); }
    .kit-specs__section.energy {
      background-image: url("/icons/energy.svg"); }
    .kit-specs__section.floors {
      background-image: url("/icons/floors.svg"); }
    .kit-specs__section.cost {
      background-image: url("/icons/cost.svg"); }
    .kit-specs__section.bedrooms {
      background-image: url("/icons/bedrooms.svg"); }
    .kit-specs__section.measurements {
      background-image: url("/icons/measurements.svg");
      border: none;
      margin-bottom: 0;
      padding-bottom: 0; }
      .kit-specs__section.measurements li {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between; }
    .kit-specs__section .h6 {
      font-size: 1.125rem;
      margin-bottom: 1rem; }
    .kit-specs__section ul {
      list-style: none;
      margin-bottom: -.5rem;
      padding: 0; }
      .kit-specs__section ul li {
        margin-bottom: .5rem; }
  .kit-specs__floorplans-main {
    margin-bottom: 1rem; }
    .kit-specs__floorplans-main-img {
      background: white;
      cursor: pointer;
      height: 0;
      overflow: hidden;
      padding-bottom: 70%;
      position: relative;
      width: 100%; }
      .kit-specs__floorplans-main-img img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .kit-specs__floorplans-main-img img {
            height: 100%;
            object-fit: contain;
            object-position: center; } }
  .kit-specs__floorplans-nav {
    padding: 0 1rem 0 2rem; }
    .kit-specs__floorplans-nav-img {
      background: white;
      height: 0;
      margin-left: 1rem;
      overflow: hidden;
      padding-bottom: 30%;
      position: relative;
      width: calc(33.33% - 1rem); }
      .kit-specs__floorplans-nav-img img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .kit-specs__floorplans-nav-img img {
            height: 100%;
            object-fit: contain;
            object-position: center; } }
    .kit-specs__floorplans-nav .flickity-button {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 0;
      height: 100%;
      transition: .25s background-color;
      width: 1.5rem; }
      .kit-specs__floorplans-nav .flickity-button.previous {
        left: 0; }
      .kit-specs__floorplans-nav .flickity-button.next {
        right: 0; }
      .kit-specs__floorplans-nav .flickity-button:hover, .kit-specs__floorplans-nav .flickity-button:focus {
        background-color: rgba(255, 255, 255, 0.1); }
      .kit-specs__floorplans-nav .flickity-button svg path {
        fill: #ffffff; }
  @media screen and (min-width: 48em) {
    .kit-specs__floorplans {
      margin-bottom: 0;
      width: 40%; }
    .kit-specs__right {
      padding-left: 3rem;
      width: 60%; }
    .kit-specs__size {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
      -ms-grid-rows: (auto)[4];
      grid-template-rows: repeat(4, auto);
      grid-column-gap: 1.5rem; }
      .kit-specs__size .sq-ft {
        grid-area: 1 / 1 / 2 / 2; }
      .kit-specs__size .energy {
        grid-area: 2 / 1 / 3 / 2; }
      .kit-specs__size .floors {
        grid-area: 3 / 1 / 4 / 2; }
      .kit-specs__size .cost {
        grid-area: 1 / 2 / 3 / 3; }
      .kit-specs__size .bedrooms {
        grid-area: 3 / 2 / 4 / 3; }
      .kit-specs__size .measurements {
        grid-area: 4 / 1 / 5 / 3; }
        .kit-specs__size .measurements ul {
          columns: 2;
          column-gap: 3rem; } }
  @media screen and (min-width: 62em) {
    .kit-specs__right {
      padding-left: 4.5rem; } }

.kit-type-overview .flickity-viewport {
  width: 100%; }

.kit-type-overview .flickity-prev-next-button {
  display: none; }

.kit-type-overview__navigation {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: -1em;
  padding: 0;
  z-index: 1; }
  .kit-type-overview__navigation .flickity-prev-next-button.previous, .kit-type-overview__navigation .flickity-prev-next-button.next {
    border-radius: 0;
    height: 4em;
    display: inline-block;
    transform: none; }
  .kit-type-overview__navigation .flickity-prev-next-button.previous {
    left: 0;
    right: auto;
    top: 0; }
  .kit-type-overview__navigation .flickity-prev-next-button.next {
    right: 0;
    top: 0; }
  .kit-type-overview__navigation .flickity-prev-next-button:disabled {
    display: none; }
  .kit-type-overview__navigation .kit-type-overview__item {
    width: 40.33%;
    border-right: 2px solid #ffffff;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-bottom: 1em;
    overflow: visible; }
    @media screen and (min-width: 62em) {
      .kit-type-overview__navigation .kit-type-overview__item {
        width: 20%; } }
    .kit-type-overview__navigation .kit-type-overview__item.is-selected .kit-type-overview__item--button {
      background-color: #04424F;
      font-weight: 600;
      transition: background-color .35s linear;
      position: relative; }
  .kit-type-overview__navigation .kit-type-overview__item--button {
    background-color: #376A72;
    border: 0;
    border-bottom: 2px solid #ffffff;
    color: #ffffff;
    height: 4em;
    overflow: hidden;
    position: relative;
    width: 100%; }
    .kit-type-overview__navigation .kit-type-overview__item--button:focus, .kit-type-overview__navigation .kit-type-overview__item--button:hover {
      background-color: #04424F;
      transition: background-color .35s linear; }

.kit-type-overview__preview {
  position: relative;
  z-index: 0;
  height: 100%; }
  .kit-type-overview__preview .flickity-viewport {
    padding-top: 120%; }
    .kit-type-overview__preview .flickity-viewport .flickity-slider {
      margin-top: -120%; }
    @media screen and (min-width: 48em) {
      .kit-type-overview__preview .flickity-viewport {
        padding-top: 50%; }
        .kit-type-overview__preview .flickity-viewport .flickity-slider {
          margin-top: -50%; } }
  .kit-type-overview__preview .pause-start-slider {
    opacity: 0; }
  .kit-type-overview__preview .carousel {
    height: 600px; }
    @media screen and (max-width: 47.999em) {
      .kit-type-overview__preview .carousel {
        height: 1100px; } }
  .kit-type-overview__preview .carousel-cell {
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: black;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    left: 100%;
    position: absolute;
    transition: left .5s ease-in-out, opacity .5s ease-in-out;
    width: 100%; }
    .kit-type-overview__preview .carousel-cell.is-selected {
      left: 0px;
      opacity: 1;
      transform: translateX(0%); }
    .kit-type-overview__preview .carousel-cell:not(.is-selected) {
      opacity: 0; }
    .kit-type-overview__preview .carousel-cell .flickity-button {
      display: block; }
    .kit-type-overview__preview .carousel-cell__floorplan-slider, .kit-type-overview__preview .carousel-cell__main-content {
      width: calc(50% - 0.75rem); }
    @media screen and (min-width: 48em) {
      .kit-type-overview__preview .carousel-cell {
        padding: 4em 4em 2em; } }
  .kit-type-overview__preview--image {
    display: block;
    background-color: #303030;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden; }
    .kit-type-overview__preview--image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .kit-type-overview__preview--image img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .kit-type-overview__preview--content {
    background: rgba(28, 33, 38, 0.95);
    display: inline-block;
    margin: 1rem;
    width: calc(100% - 2rem);
    padding: 1em;
    position: relative; }
    @media screen and (min-width: 48em) {
      .kit-type-overview__preview--content {
        margin: 0;
        max-width: 19em;
        padding: 4em 2em; } }

@media screen and (max-width: 47.999em) {
  .kit-type-overview .carousel-cell {
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.5rem; }
    .kit-type-overview .carousel-cell__floorplan-slider, .kit-type-overview .carousel-cell__main-content {
      width: 100%; }
    .kit-type-overview .carousel-cell__main-content .button {
      white-space: normal; }
  .kit-type-overview .pause-start-slider {
    margin: .5rem; }
  .kit-type-overview__navigation {
    background-color: #1d2e34;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0; }
    .kit-type-overview__navigation .kit-type-overview__item {
      margin-bottom: 0;
      width: 50%; } }

/**
 * Page Header Flex Layout
 * DBS>Interactive
 */
.landscape-cta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: auto;
  margin-top: 0;
  position: relative;
  width: 100%; }
  .landscape-cta__image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
    .landscape-cta__image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .landscape-cta__image img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .landscape-cta__content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[1];
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
    width: 100%; }
  .landscape-cta__row {
    background: rgba(28, 33, 38, 0.95);
    padding: 4em 2em;
    position: relative;
    opacity: 0;
    transform: translate3d(0, 1.5rem, 0);
    transition: 0.75s opacity ease-in-out, 0.75s transform ease-in-out; }
    @media screen and (min-width: 48em) {
      .landscape-cta__row {
        margin: 5em 0 5em 0;
        max-width: 22em; } }
    .landscape-cta__row h2, .landscape-cta__row h3, .landscape-cta__row h4, .landscape-cta__row h5, .landscape-cta__row h6, .landscape-cta__row p, .landscape-cta__row a, .landscape-cta__row ul, .landscape-cta__row li {
      color: #ffffff; }
    .landscape-cta__row p a:not(.button) {
      border-color: #ffffff;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .landscape-cta__row p a:not(.button):after {
        background-color: transparent; }
    .landscape-cta__row:nth-child(2) {
      transition-delay: 0.35s; }
    .landscape-cta__row:nth-child(3) {
      transition-delay: 0.7s; }
    .landscape-cta__row.in-view {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
  @media screen and (min-width: 48em) {
    .landscape-cta__content {
      -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr); } }

.mighty-ideas .flickity-viewport {
  width: 100%; }

.mighty-ideas .flickity-prev-next-button {
  display: none; }

.mighty-ideas__navigation {
  margin-bottom: -1em;
  padding: 0;
  z-index: 1; }
  .mighty-ideas__navigation .flickity-prev-next-button.previous, .mighty-ideas__navigation .flickity-prev-next-button.next {
    border-radius: 0;
    height: 4em;
    display: inline-block;
    transform: none; }
  .mighty-ideas__navigation .flickity-prev-next-button.previous {
    left: 0;
    right: auto;
    top: 0; }
  .mighty-ideas__navigation .flickity-prev-next-button.next {
    right: 0;
    top: 0; }
  .mighty-ideas__navigation .flickity-prev-next-button:disabled {
    display: none; }
  .mighty-ideas__navigation .mighty-ideas__item {
    width: 40.33%;
    border-right: 2px solid #ffffff;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-bottom: 1em;
    overflow: visible; }
    @media screen and (min-width: 62em) {
      .mighty-ideas__navigation .mighty-ideas__item {
        width: 20%; } }
    .mighty-ideas__navigation .mighty-ideas__item.is-selected .mighty-ideas__item--button {
      background-color: #04424F;
      font-weight: 600;
      transition: background-color .35s linear;
      position: relative; }
  .mighty-ideas__navigation .mighty-ideas__item--button {
    background-color: #376A72;
    border: 0;
    border-bottom: 2px solid #ffffff;
    color: #ffffff;
    height: 4em;
    overflow: hidden;
    position: relative;
    width: 100%; }
    .mighty-ideas__navigation .mighty-ideas__item--button:focus, .mighty-ideas__navigation .mighty-ideas__item--button:hover {
      background-color: #04424F;
      transition: background-color .35s linear; }

.mighty-ideas__preview {
  background-color: #F0F3F5;
  position: relative;
  z-index: 0;
  height: 100%; }
  .mighty-ideas__preview .flickity-viewport {
    padding-top: 120%; }
    .mighty-ideas__preview .flickity-viewport .flickity-slider {
      margin-top: -120%; }
    @media screen and (min-width: 48em) {
      .mighty-ideas__preview .flickity-viewport {
        padding-top: 50%; }
        .mighty-ideas__preview .flickity-viewport .flickity-slider {
          margin-top: -50%; } }
  .mighty-ideas__preview .carousel-cell {
    height: 100%;
    display: block;
    left: 100%;
    position: absolute;
    width: 100%; }
    @media screen and (min-width: 48em) {
      .mighty-ideas__preview .carousel-cell {
        padding: 4em 4em 2em; } }
  .mighty-ideas__preview--image {
    display: block;
    background-color: #303030;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden; }
    .mighty-ideas__preview--image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .mighty-ideas__preview--image img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .mighty-ideas__preview--content {
    background: rgba(28, 33, 38, 0.95);
    display: inline-block;
    margin: 1rem;
    width: calc(100% - 2rem);
    padding: 1em;
    position: relative; }
    @media screen and (min-width: 48em) {
      .mighty-ideas__preview--content {
        margin: 0;
        max-width: 19em;
        padding: 4em 2em; } }

@media screen and (max-width: 47.999em) {
  .mighty-ideas .pause-start-slider {
    margin: .5rem; } }

/**
 * Page Header Flex Layout
 * DBS>Interactive
 */
.page-header__text {
  animation: opacity 0.75s ease-in-out both, slideRight 0.75s ease-out both;
  margin-bottom: 1.5rem; }
  .page-header__text hr {
    border-color: #86D1D4;
    border-width: 3px;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    margin-left: 0;
    width: 5rem; }
    .page-header__text hr:first-child {
      margin-top: 0; }

.page-header__image {
  animation: opacity 0.75s 0.375s ease-in-out both; }
  .page-header__image-wrapper {
    height: 0;
    overflow: hidden;
    padding-bottom: 92%;
    position: relative; }
    .page-header__image-wrapper img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .page-header__image-wrapper img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }

@media screen and (min-width: 48em) {
  .page-header__text {
    margin-bottom: 0;
    padding-right: 3rem;
    width: 50%; }
  .page-header__image {
    width: 50%; } }

@media screen and (min-width: 62em) {
  .page-header__text {
    width: 45%; }
  .page-header__image {
    width: 55%; }
  .page-header.project-header .page-header__text {
    width: 50%; }
  .page-header.project-header .page-header__image {
    width: 50%; } }

@media screen and (min-width: 62em) {
  .page-header {
    background: linear-gradient(180deg, #474747 0%, #232323 43.76%), #D9D9D9;
    margin-top: 0;
    padding-top: 12rem; } }

.pinterest {
  padding: 0 1em;
  text-align: center;
  overflow: hidden; }
  @media screen and (min-width: 62em) {
    .pinterest {
      padding: 0; } }
  .pinterest .partial-pinterest__items {
    list-style: none;
    padding: 0;
    position: relative; }
  .pinterest .partial-pinterest__item {
    list-style: none;
    width: 100%; }
    @media screen and (max-width: 47.999em) {
      .pinterest .partial-pinterest__item {
        left: 0 !important; } }
    @media screen and (min-width: 48em) {
      .pinterest .partial-pinterest__item {
        float: left;
        width: calc(33% - .6rem); } }
    @media screen and (min-width: 62em) {
      .pinterest .partial-pinterest__item {
        width: calc(25% - .6rem); } }
    .pinterest .partial-pinterest__item a {
      display: block;
      height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%; }
      .pinterest .partial-pinterest__item a:after {
        background: rgba(48, 48, 48, 0.3);
        content: '';
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity .5s;
        width: 100%; }
      .pinterest .partial-pinterest__item a:hover:after, .pinterest .partial-pinterest__item a:focus:after {
        opacity: 1; }
      .pinterest .partial-pinterest__item a img {
        display: block;
        height: 100%;
        width: 100%; }

.project-body__gallery-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
  grid-template-columns: repeat(3, 1fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  gap: 1rem;
  margin-bottom: 3rem; }

.project-body__gallery-img {
  appearance: none;
  background: #232323;
  border: none;
  height: 0;
  overflow: hidden;
  padding-bottom: 80%;
  position: relative; }
  .project-body__gallery-img img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .project-body__gallery-img img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.project-body__meta li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 1rem;
  padding-bottom: 1rem; }
  .project-body__meta li:last-child {
    border: none; }
  .project-body__meta li span,
  .project-body__meta li strong,
  .project-body__meta li .floorplan__image {
    -ms-flex: 1;
    flex: 1; }
  .project-body__meta li span {
    text-align: right; }
  .project-body__meta li .floorplan__image {
    appearance: none;
    border: none; }
  .project-body__meta li #floorplan-lightbox__main {
    width: 100%; }
    .project-body__meta li #floorplan-lightbox__main img {
      object-fit: contain !important; }

.project-body__meta .model-type__title {
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-right: 1rem;
  text-decoration: none;
  width: 50%; }

.project-body__meta .model-type__image {
  height: 0;
  padding-bottom: 25%;
  position: relative;
  overflow: hidden;
  width: 50%; }
  .project-body__meta .model-type__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .project-body__meta .model-type__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

@media screen and (min-width: 48em) {
  .project-body__gallery {
    margin-bottom: 0;
    width: 60%; }
  .project-body__meta {
    margin-top: 4rem;
    padding-left: 3rem;
    width: 40%; } }

.project-quote.layout-more-narrow {
  background: #1d2e34;
  text-align: left; }
  .project-quote.layout-more-narrow .contain {
    position: relative; }
    .project-quote.layout-more-narrow .contain::before {
      background: url("/icons/quote.svg") no-repeat center;
      background-size: contain;
      content: '';
      height: 3rem;
      position: absolute;
      top: 0;
      left: -3rem;
      width: 3rem; }
  @media screen and (min-width: 48em) {
    .project-quote.layout-more-narrow {
      padding-bottom: 6rem;
      padding-top: 6rem; } }

.project-quote + .blueprint {
  margin-top: 0; }

.story-slider {
  position: relative; }
  .story-slider .contain {
    padding-right: 3rem; }
  .story-slider__slides {
    position: relative;
    width: 100%; }
  .story-slider__text {
    margin-bottom: 3rem;
    position: relative;
    z-index: 5; }
    .story-slider__text--block {
      margin-right: 1.5rem;
      width: 100%; }
  .story-slider__images--block {
    margin-right: 1.5rem;
    width: 100%; }
  .story-slider .flickity-button {
    background: #232323;
    border-radius: 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 40%;
    width: 3.5rem;
    z-index: 10; }
    .story-slider .flickity-button.next {
      right: 0; }
    .story-slider .flickity-button.previous {
      left: 0; }
    .story-slider .flickity-button svg {
      left: 27%;
      width: 1.75rem;
      fill: #fff; }
    .story-slider .flickity-button:disabled {
      display: none; }
  .story-slider__images .flickity-viewport {
    overflow: visible; }
  .story-slider__caption {
    margin-top: .75rem;
    text-align: center; }
  @media screen and (max-width: 47.999em) {
    .story-slider .flickity-button.next {
      right: -3rem; }
    .story-slider .flickity-button.previous {
      left: -1.5rem; } }
  @media screen and (min-width: 48em) {
    .story-slider.layout {
      margin: 6rem auto; }
    .story-slider .contain {
      padding-right: 1.5rem; }
    .story-slider::after {
      opacity: 0.64;
      background: linear-gradient(270deg, #232323 0%, rgba(35, 35, 35, 0) 100%);
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 6rem;
      z-index: 1; }
    .story-slider__text {
      margin-bottom: 0;
      padding-right: 4rem;
      width: 50%; }
      .story-slider__text p {
        font-size: 1.125rem; }
    .story-slider__images {
      width: 50%; }
      .story-slider__images::before {
        background: #232323;
        content: '';
        height: 110%;
        position: absolute;
        right: 100%;
        top: 0;
        width: 100vw;
        z-index: 1; }
    .story-slider .flickity-button.previous {
      left: -3rem; }
    .story-slider .flickity-button.next {
      right: -1.5rem; } }
  @media screen and (min-width: 62em) {
    .story-slider__slide {
      margin-right: 3rem; } }
  @media screen and (min-width: 79rem) {
    .story-slider .flickity-button.next {
      right: auto;
      left: 46.25vw; } }
  @media screen and (min-width: 100rem) {
    .story-slider .flickity-button.next {
      right: auto;
      left: 47vw; } }
  @media screen and (min-width: 117rem) {
    .story-slider .flickity-button.next {
      right: auto;
      left: 47.5vw; } }
  @media screen and (min-width: 141rem) {
    .story-slider .flickity-button.next {
      right: auto;
      left: 48vw; } }
  @media screen and (max-width: 47.999em) {
    .story-slider .flickity-button {
      top: auto;
      bottom: 0;
      transform: none;
      height: 40%;
      width: 2.5rem; }
      .story-slider .flickity-button svg {
        left: 20%;
        width: 1rem; }
    .story-slider__images--block {
      margin-right: 0;
      padding-left: 1rem; } }

.testimonial__text {
  background: url("/icons/quote.svg") no-repeat left top;
  background-size: 2rem auto;
  margin-bottom: 3rem;
  padding-left: 3rem; }
  .testimonial__text .h6 {
    font-size: 1rem; }

.testimonial__image-wrapper {
  height: 0;
  overflow: hidden;
  padding-bottom: 65%;
  position: relative; }
  .testimonial__image-wrapper img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .testimonial__image-wrapper img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

@media screen and (min-width: 48em) {
  .testimonial__text {
    background-size: 2.5rem;
    margin-bottom: 0;
    padding-left: 4.5rem;
    padding-right: 3rem; }
  .testimonial__image {
    padding-left: 4.5rem; } }

/**
 * Two Columns Flex Layout
 * DBS Interactive
 */
.two-columns__left, .two-columns__right {
  opacity: 0;
  position: relative;
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }
  .two-columns__left img, .two-columns__right img {
    display: block; }
  .two-columns__left.has-img, .two-columns__right.has-img {
    opacity: 0;
    transition: opacity 0.75s ease-in-out; }

.two-columns__left {
  transform: translate3d(-2rem, 0, 0); }

.two-columns__right {
  transform: translate3d(2rem, 0, 0); }

.two-columns .animation-trigger.in-view ~ .has-img {
  opacity: 1; }

.two-columns .animation-trigger.in-view ~ .two-columns__left,
.two-columns .animation-trigger.in-view ~ .two-columns__right {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

@media screen and (max-width: 47.999em) {
  .two-columns__left {
    margin-bottom: 1.5rem; } }

@media screen and (min-width: 48em) {
  .two-columns__left, .two-columns__right {
    width: calc(50% - 3rem); }
    .two-columns__left.contain-img img, .two-columns__right.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.contain-img img, .two-columns__right.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-columns__left.cover img, .two-columns__right.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.cover img, .two-columns__right.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .two-columns__left.contain-img, .two-columns__left.cover, .two-columns__right.contain-img, .two-columns__right.cover {
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch; } }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-right .two-columns__left {
    width: calc(38% - 3rem); } }

.two-columns.two-thirds-right img {
  height: auto;
  width: 100%; }

.two-columns.two-thirds-right .two-columns__right.bg-img {
  padding-left: 0; }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-right .two-columns__right {
    width: calc(62% - 3rem); } }

.two-columns.two-thirds-left img {
  height: auto;
  width: 100%; }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-left .two-columns__left {
    width: calc(62% - 3rem); }
    .two-columns.two-thirds-left .two-columns__left.bg-img {
      padding-right: 0; }
  .two-columns.two-thirds-left .two-columns__right {
    width: calc(38% - 3rem); } }

/**
 * Collapsible Layout
 * DBS>Interactive
 */
.collapsible {
  margin: 3rem auto; }
  @media screen and (min-width: 48em) {
    .collapsible {
      margin: 4.5rem auto; } }
  .collapsible .collapsibles-wrapper {
    border: 1px solid #363535;
    border-radius: 12px;
    overflow: hidden; }
  .collapsible .collapsible-row {
    overflow: hidden;
    margin-bottom: .5rem; }
    .collapsible .collapsible-row:first-of-type {
      border: none; }
    .collapsible .collapsible-row:last-of-type .collapsible-row-content .rich-text {
      border-bottom: none; }
    .collapsible .collapsible-row:last-of-type .collapsible-row-header {
      border-bottom: none; }
    .collapsible .collapsible-row__header {
      background: #303030;
      border: none;
      color: #ffffff;
      cursor: pointer;
      font-weight: normal;
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 1.5rem 4rem 1.5rem 1.5rem;
      position: relative;
      text-align: left;
      transition: background .25s;
      width: 100%; }
      .collapsible .collapsible-row__header:hover, .collapsible .collapsible-row__header:focus, .collapsible .collapsible-row__header:active {
        background: #055C6E;
        box-shadow: none !important; }
      .collapsible .collapsible-row__header:after {
        background: url("/icons/collapsible-open.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        content: '';
        display: block;
        height: 2rem;
        position: absolute;
        top: 50%;
        right: 2rem;
        transition: transform .25s;
        transform: translate(0, -50%) scale(0.8);
        width: 2rem; }
      .collapsible .collapsible-row__header.active {
        background: #055C6E; }
        .collapsible .collapsible-row__header.active:after {
          transform: translate(0, -50%) scale(0.8) rotate(180deg); }
    .collapsible .collapsible-row__content--wrapper {
      background: #04424F;
      height: 0;
      overflow: hidden;
      transition: height .3s ease-in-out 0s; }
      .collapsible .collapsible-row__content--wrapper .collapsible-row__content {
        border-top: 0.5rem solid #232323;
        padding: 2rem; }
  .collapsible .collapsible-section {
    margin: 3rem auto; }
    @media screen and (min-width: 48em) {
      .collapsible .collapsible-section {
        margin: 4.5rem auto; } }
    .collapsible .collapsible-section:first-child {
      margin-top: 0; }

/**
 * Image Grid (with sidebar)
 * DBS>Interactive
 *
 * ACF Special Classes styled by this stylesheet:
 *		.light-box (Light Box)
 *		.image-zoom (Image Zoom)
 *
 * Note: The .light-box class included later in this stylesheet
 * 		cooresponds with functionality included in lightbox/lightbox.js
 */
.image-gallery__gallery {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%; }
  .image-gallery__gallery-main {
    height: 0;
    overflow: hidden;
    padding-bottom: calc(100% * 2 / 3);
    position: relative;
    width: 100%; }
    .image-gallery__gallery-main img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .image-gallery__gallery-main img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .image-gallery__gallery-thumbs {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[4];
    grid-template-columns: repeat(4, 1fr);
    -ms-grid-rows: min-content;
    grid-template-rows: min-content;
    gap: 1rem;
    width: 100%; }
    .image-gallery__gallery-thumbs button {
      border: none; }
  .image-gallery__gallery-thumb {
    max-height: 4.2rem;
    padding-bottom: 90%;
    position: relative;
    width: 100%; }
    .image-gallery__gallery-thumb img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .image-gallery__gallery-thumb img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }

@media screen and (min-width: 48em) {
  .image-gallery__gallery {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
    .image-gallery__gallery-main {
      padding-bottom: calc(100% / 3); }
    .image-gallery__gallery-main, .image-gallery__gallery-thumbs {
      width: calc(50% - 0.75rem); } }

/**
 * Mixin to create a multi-directional animation with opacity fade.
 *
 * @example: 
 * @include animate3D( fadeRight, -3rem, 0, 0, 0);
 * .slideRight {animation: fadeRight 1s ease 0s 1 both;}
 */
/**
 * Several basic examples are below, but they are being kept commented out
 * to keep unnecessary code from making its way into production. For new animations
 * that involve a "transform: translate", use "translate3d" since it will perform better 
 * because of hardware acceleration.
 */
/**
 * Slide left to right and fade in from invisible 
 */
/**
 * Slide right to left and fade in from invisible 
 */
/**
 * Slide up and fade in from invisible 
 */
/**
 * Slide down and fade in from invisible 
 */
/**
 * Fade in only
 */
/**
 * Bounce
 */
/**
 * Not enough? This site has a lot of great animations with the ability to copy the
 * source code. https://animista.net/play/
 */
@keyframes opacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes partialOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: .25; } }

@keyframes slideRight {
  0% {
    transform: translate3d(-3rem, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

/**
 * Breadcrumb styles
 * DBS>Interactive
 */
.breadcrumbs {
  padding: 1rem 0; }
  .breadcrumbs a {
    color: #86D1D4;
    font-weight: 400;
    text-decoration: none; }
    .breadcrumbs a:active, .breadcrumbs a:focus, .breadcrumbs a:hover {
      color: #bee6e8; }

.flex {
  display: -ms-flexbox;
  display: flex; }

.flex-col {
  -ms-flex-direction: column;
  flex-direction: column; }

.flex-col-r {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-row-r {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.aic {
  -ms-flex-align: center;
  align-items: center; }

.aie {
  -ms-flex-align: end;
  align-items: flex-end; }

.asc {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center; }

.ase {
  -ms-flex-item-align: end;
  align-self: flex-end; }

.ass {
  -ms-flex-item-align: start;
  align-self: flex-start; }

.jcc {
  -ms-flex-pack: center;
  justify-content: center; }

.jce {
  -ms-flex-pack: end;
  justify-content: flex-end; }

.jcsa {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.jcsb {
  -ms-flex-pack: justify;
  justify-content: space-between; }

.jcse {
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly; }

.fb-25 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%; }

.fb-50 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%; }

.fb-75 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%; }

.gap {
  gap: 1.5rem; }

.gap-2 {
  gap: 3rem; }

@media screen and (max-width: 47.999em) {
  .flex-sm {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-sm {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-sm {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-sm {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-sm {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .wrap-sm {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .aic-sm {
    -ms-flex-align: center;
    align-items: center; }
  .aie-sm {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-sm {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-sm {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-sm {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-sm {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-sm {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-sm {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-sm {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .fb-25-sm {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-sm {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-sm {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 48em) {
  .flex-tab {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-tab {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-tab {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-tab {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-tab {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-tab {
    -ms-flex-align: center;
    align-items: center; }
  .aie-tab {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-tab {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-tab {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-tab {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-tab {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-tab {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-tab {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-tab {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-tab {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-tab {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-tab {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-tab {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-tab {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-tab {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 62em) {
  .flex-md {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-md {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-md {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-md {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-md {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-md {
    -ms-flex-align: center;
    align-items: center; }
  .aie-md {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-md {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-md {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-md {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-md {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-md {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-md {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-md {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-md {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-md {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-md {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-md {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-md {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 75em) {
  .flex-lg {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-lg {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-lg {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-lg {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-lg {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-lg {
    -ms-flex-align: center;
    align-items: center; }
  .aie-lg {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-lg {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-lg {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-lg {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-lg {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-lg {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-lg {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-lg {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-lg {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-lg {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-lg {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-lg {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

/**
 * Form Styles
 * DBS>Interactive
 */
.form {
  /**
	* Field Widths/Wrapping
	*/ }
  .form ul {
    list-style: none;
    padding: 0; }
  .form label, .form legend {
    font-weight: bold; }
  .form__field {
    margin-bottom: 1em; }
  .form .error-text {
    border-bottom: 0.1em solid #363535;
    color: #363535;
    padding-bottom: 1em; }
  @media screen and (min-width: 48em) {
    .form {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 -.75rem; }
      .form__field-wrapper {
        padding: 0 .75rem;
        width: 100%; }
        .form__field-wrapper.width-25 {
          width: 25%; }
        .form__field-wrapper.width-33 {
          width: 33.33%; }
        .form__field-wrapper.width-50 {
          width: 50%; }
        .form__field-wrapper.width-66 {
          width: 66.66%; }
        .form__field-wrapper.width-75 {
          width: 75%; }
      .form .button {
        margin-left: .75rem; } }

fieldset > label.gfield_label_before_complex,
fieldset legend.gfield_label_before_complex,
.hidden_label > label.gfield_label_before_complex,
.hidden_label legend.gfield_label_before_complex,
.hide-label > label.gfield_label_before_complex,
.hide-label legend.gfield_label_before_complex {
  display: none; }

fieldset > label, fieldset legend,
.hidden_label > label,
.hidden_label legend,
.hide-label > label,
.hide-label legend {
  height: 1px;
  width: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

/**
 * Input Field Styles
 */
textarea {
  min-height: 5em; }

input[type=radio] {
  margin-right: .5em; }

select {
  min-width: 25%;
  margin: 0; }

textarea,
input[type=text]:not([id=blog-search-field]),
input[type=email],
input[type=date],
input[type=number],
input[type=time],
input[type=week],
input[type=month],
input[type=tel],
input[type=search],
input[type=url],
input[type=password],
.gfield select {
  display: block;
  border: 1px solid #E9E9E9;
  margin: .333em 0;
  max-width: 100%;
  padding: .666em;
  width: 100%; }

fieldset {
  border: none;
  margin-bottom: 3rem;
  padding: 0 !important; }

/**
 * Validation and error message styling
 */
.error-text {
  color: red; }

/**
 * Animated Form Label
 *
 * Class must be added in the GForms admin
 */
.animate-label {
  position: relative; }
  .animate-label .ginput_complex > span {
    height: 100%;
    width: 100%;
    display: inline-block;
    position: relative; }
    .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label .ginput_complex > span.active label, .animate-label .ginput_complex > span.active legend {
      transform: translateY(-2.25rem) scale(0.75); }
  .animate-label .ginput_complex.gfield_error label {
    transform: translateY(-2.25rem) scale(0.75); }
  .animate-label label, .animate-label legend {
    background: white;
    padding: 0 .25rem;
    position: absolute;
    top: 50%;
    left: .5rem;
    transition: .2s ease-in-out;
    transform: translateY(-50%);
    transform-origin: left; }
  .animate-label.select-field select {
    font-weight: 700;
    padding: 1.5rem .75rem; }
  .animate-label.text-area label {
    top: .5rem;
    transform: none; }
  .animate-label.text-area textarea {
    max-height: 12rem;
    padding-top: 3rem; }
  .animate-label.text-area.gfield_error label, .animate-label.text-area.gfield_error legend {
    transform: none; }
  .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label.active label, .animate-label.active legend {
    transform: translateY(-2.25rem) scale(0.75); }

.customer-login .form {
  margin: 0 auto;
  max-width: 35rem; }

.customer-login .field {
  margin-bottom: 1rem;
  width: 100%; }

.customer-login__links {
  text-align: center; }
  .customer-login__links .button {
    margin: 0;
    width: 100%; }

/**
 * Pagination Styles
 * DBS>Interactive
 */
.pagination {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center; }
  .pagination .button.previous {
    padding: 1.5rem 2rem 1.5rem 4rem; }
    .pagination .button.previous::after, .pagination .button.previous::before {
      left: 1.25rem;
      right: unset;
      transform: rotate(180deg) translate(0%, 50%); }
    .pagination .button.previous:hover::after, .pagination .button.previous:hover::before {
      transform: rotate(180deg) translate(0.5rem, 50%); }
  .pagination__link, .pagination__more, .pagination__current,
  .pagination .page-numbers {
    display: inline-block;
    padding: 0.5em 1em;
    text-decoration: none;
    border-radius: 3px;
    transition: .25s ease-in-out; }
  .pagination__link,
  .pagination .page-numbers {
    border: 1px solid #232323;
    font-weight: lighter; }
    .pagination__link:focus, .pagination__link:hover,
    .pagination .page-numbers:focus,
    .pagination .page-numbers:hover {
      background: #232323;
      color: #ffffff; }
  .pagination__current {
    background: #232323;
    border: 1px solid #232323;
    font-weight: bold;
    color: #ffffff; }

.product-pagination__links > a,
.product-pagination__links > span {
  padding: 1em;
  border: 1px solid #363535; }

/**
 * Table Styles with Responsive Design
 * Based on a CodePen Example by James TW:
 * https://codepen.io/james-tw/pen/GoaXrz
 */
/* Variables */
/**
 * Default styles
 */
table {
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin: 0 auto 1em;
  max-width: 100%;
  min-width: 400px;
  width: 100%;
  /**
	 * Responsive desktop styles
	 */
  /**
	 * Mobile only styles
	 */ }
  table tbody {
    display: block; }
    table tbody tr {
      border-bottom: 1px solid #86D1D4; }
      table tbody tr:nth-of-type(even) {
        background-color: #232323; }
      table tbody tr:last-of-type {
        border-bottom: 2px solid #86D1D4; }
  table thead tr {
    background-color: #376A72;
    color: #ffffff;
    text-align: left; }
  table tr {
    border: 1px solid #e0e0e0;
    display: block;
    margin-bottom: 12px 15px;
    transition: background-color .25s ease; }
  table th,
  table td {
    border-top: 0;
    padding: 12px 15px;
    transition: background-color .25s ease;
    text-align: left;
    vertical-align: top; }
  table th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: #ffffff;
    font-weight: 400;
    vertical-align: bottom; }
  table td {
    display: block;
    text-align: right; }
  table tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
    table tfoot td {
      color: #ffffff;
      font-weight: 400; }
  @media screen and (min-width: 48em) {
    table tbody {
      display: table-row-group; }
    table tr {
      border: none;
      display: table-row; }
    table td {
      display: table-cell;
      text-align: left; } }
  @media screen and (max-width: 47.999em) {
    table thead,
    table tfoot {
      display: none; }
    table td[data-title]:before {
      color: #ffffff;
      content: attr(data-title);
      float: left;
      font-size: inherit;
      font-weight: 400; }
    table:not(._) td:first-child {
      background-color: #232323;
      border: none;
      color: #ffffff;
      font-weight: 500;
      text-align: left; }
      table:not(._) td:first-child::before {
        display: none; } }

.blog-post__content.bg-white thead h1, .blog-post__content.bg-white thead h2, .blog-post__content.bg-white thead h3, .blog-post__content.bg-white thead h4, .blog-post__content.bg-white thead h5, .blog-post__content.bg-white thead h6 {
  color: white; }

.blog-post__content.bg-white table tbody tr {
  border-bottom: 1px solid #86D1D4; }
  .blog-post__content.bg-white table tbody tr:nth-of-type(even) {
    background-color: #F0F3F5; }
  .blog-post__content.bg-white table tbody tr:last-of-type {
    border-bottom: 2px solid #86D1D4; }

/* Table classes -- Add manually to the table element within the CMS */
/**
 * Bordered table
 *
 * Add horizontal borders between columns.
 */
.table--bordered {
  border: 0; }
  .table--bordered th,
  .table--bordered td {
    border-bottom: 1px solid #e0e0e0; }
    @media screen and (min-width: 48em) {
      .table--bordered th,
      .table--bordered td {
        border: 1px solid #e0e0e0; } }
  .table--bordered thead th,
  .table--bordered thead td {
    border-bottom-width: 2px; }

/**
 * Zebra-striping
 * Default zebra-stripe styles (alternating gray and transparent backgrounds)
 */
.table--stripes td:nth-child(odd) {
  background-color: #363535; }

/**
 * Hover effect styling
 */
@media screen and (min-width: 48em) {
  .table--hover tr:hover {
    cursor: pointer; }
    .table--hover tr:hover td,
    .table--hover tr:hover td:first-child {
      background-color: rgba(0, 0, 0, 0.05); } }

/**
 * Sitemap page styles
 * DBS>Interactive
 */
.sitemap__section {
  margin-bottom: 3rem; }
